import { PageProductList, ContactUs, AboutUs, Loading } from "../../Components";
import {
  HeaderSection,
  HomePageCustomer,
  HomePartnership,
  Service,
} from "./Components";
import { useEffect, useState } from "react";
import { homePageDataLoad } from "../../Api";
import React from "react";
import ProductDataTable from "../../Components/Product-data-table/ProductDataTable";

const HomePage = () => {
  const [homeData, setHomeData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //home page data load
  const dataFetch = async () => {
    setIsLoading(true);
    try {
      const data = await homePageDataLoad();
      setHomeData(data);
    }finally{
      setIsLoading(false)
    }
  };

  useEffect(() => {
    dataFetch();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <HeaderSection />
          <Service />

          {/* Latest selling items */}
          <PageProductList
            ItemData={homeData?.latestItem}
            title="Latest Products"
            subtitle={` Welcome to our Latest Product Section, where innovation and excellence
            meet to bring you the cutting-edge solutions you've been
            waiting for! Explore our newest arrivals, handpicked to elevate your
            experience and cater to your evolving needs.`}
          />

          <div className="col-12 col-md-10 my-5 mx-auto shadow container-fluid overflow-x-scroll">
            <ProductDataTable isAdmin={false} />
          </div>

          {/* helmets */}
          <PageProductList
            ItemData={homeData?.helmetItem}
            title="New helmets"
            subtitle={`Safety meets style in our newest helmet collection. Ride confidently with the latest designs and features.
            Protect your journey with cutting-edge technology and comfort. `}
          />

          <HomePartnership />

          {/* Motor Bike Items */}
          <PageProductList
            ItemData={homeData?.bikeItem}
            title="MotorBike Spareparts"
            subtitle={`Revitalize your ride with our premium selection of motorbike spare parts. Engineered for maximum performance and longevity,
            these parts ensure your bike runs smoothly mile after mile.`}
          />

          {/* 3wheel Items */}
          <PageProductList
            ItemData={homeData?.wheelItem}
            title="ThreeWheel Spareparts"
            subtitle={`Upgrade your three-wheel ride with top-notch parts, ensuring reliability and peak performance for every journey. 
            Experience a smoother and safer drive with quality components designed to enhance your driving experience.`}
          />

          {/* modification Items */}
          <PageProductList
            ItemData={homeData?.modificatioItems}
            title="Modification Items"
            subtitle={`Transform your vehicle with our range of modification items. Personalize your ride and boost its performance with our innovative solutions,
            tailored to meet your unique needs and style preferences.`}
          />

          <AboutUs />

          <HomePageCustomer />

          <ContactUs />
        </>
      )}
    </>
  );
};

export default HomePage;
