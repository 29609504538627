import axios from "axios";
import { SERVER_LINK } from "../../../Constant";

export const addColors = async (color: any, code: any) => {
  const data = {
    color: color,
    code: code,
  };

  try {
    const encodedData = encodeURIComponent(JSON.stringify(data));
    const dataObject = await axios.get(
      `${SERVER_LINK}/admin/category/add-color.php?inputs=${encodedData}`
    );
    return dataObject.data;
  } catch (error: any) {
    console.error("Error fetching data:", error.message);
    return null;
  }
};
