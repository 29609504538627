import { SERVER_LINK } from "../../Constant";
import axios from "axios";

export const insertOrderData = async (dataArray: any) => {
  try {
    const formData = new FormData();
    formData.append('inputs', JSON.stringify(dataArray));

    const dataObject = await axios.post(
      `${SERVER_LINK}/home/insert-order-data.php`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return dataObject.data;
  } catch (error: any) {
    console.error("Error fetching data:", error.message);
  }
};
