import { useNavigate } from "react-router-dom";
import { Loading, PaymentModal, QtyItem } from "../../Components";
import { IMAGE_URL, PRODUCT_PAGE } from "../../Constant";
import { useCartContext } from "../../Context";
import { cartPageDataLoad } from "../../Api";
import { useIsMobile } from "../../Utils";
import { Button, Empty, Typography } from "antd";
import React, { useEffect, useState } from "react";
import TruncatedTextWithTooltip from "../../Components/TruncatedTextWithTooltip/TruncatedTextWithTooltip";

const Cart = () => {
  const isMobile = useIsMobile();
  const [cartItem, setCartItem] = useState<String[]>([]);
  const navigate = useNavigate();
  const data = useCartContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const cartItemsFromSession = sessionStorage.getItem("cartItems");
      const data = await cartPageDataLoad(cartItemsFromSession || []);
      setCartItem(data.product);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [data.cartItems]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const handlleEmptyBtn = () => {
    navigate(`../${PRODUCT_PAGE}`);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <div className="container-fluid row d-flex align-items-start pe-0">
            <div className="col-12 col-lg-7 mx-auto d-flex flex-wrap pe-0">
              <div className="col-12 d-flex flex-row flex-wrap justify-content-between">
                {/* Cat item */}
                {cartItem && cartItem?.length > 0 ? (
                  cartItem?.map((data: any, index: number) => (
                    <>
                      <div
                        key={index}
                        className="col-12 col-lg-6 d-flex flex-row my-3 shadow rounded py-0 align-items-center"
                      >
                        <div className="col-5 ms-0 ps-0">
                          <img
                            src={`${IMAGE_URL}/product/${data.image}`}
                            width={isMobile ? 120 : 150}
                            height={isMobile ? 150 : 150}
                            className="rounded m-0 p-0"
                            alt="Product"
                          />
                        </div>
                        <div className="col-7">
                          <div className="d-flex flex-column text-center">
                            <span className="fw-bold text-center fs-5">
                              <TruncatedTextWithTooltip
                                text={data.title}
                                maxLength={15}
                              />
                            </span>
                            <span>Price : {data.price}.00</span>
                          </div>
                          <div>
                            <QtyItem
                              productData={{ id: data.id, price: data.price }}
                              isNote={false}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <>
                    <Empty
                      className="mx-auto"
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{ height: 150 }}
                      description={
                        <Typography.Text>Your Cart is Empty</Typography.Text>
                      }
                    >
                      <Button type="default" onClick={handlleEmptyBtn}>
                        Collect Items{" "}
                      </Button>
                    </Empty>
                  </>
                )}
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div>
                {cartItem && cartItem?.length > 0 ? (
                  <>
                    <PaymentModal />
                  </>
                ) : (
                  <div>
                    <img
                      src={`${IMAGE_URL}/payment-image.jpg`}
                      className="w-100"
                      alt=""
                    />
                  </div>
                )}
              </div>
              <div className="col-12 text-end">
                {cartItem && cartItem?.length > 0 && (
                  <>
                    <Button
                      type="primary"
                      className="w-25 bg-danger shadow py-2"
                      loading={false}
                      iconPosition={"end"}
                      onClick={() => {
                        data.clearCartSession();
                        fetchData();
                      }}
                    >
                      clear cart
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
};

export default Cart;
