import React from "react";
import ProductSearch from "../../Components/product-search/ProductSearch";
import ProductDataTable from "../../Components/Product-data-table/ProductDataTable";

const ProductsManage = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <ProductSearch isAdmin={true} />
          </div>

          <div className="col-10 mx-auto my-5">
            <ProductDataTable isAdmin={true} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsManage;
