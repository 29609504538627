import { HOME_PAGE, IMAGE_URL, orderStatus } from "../../Constant";
import { domexTrckingSystem, orderDataLoad } from "../../Api";
import {
  CreditCardFilled,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Empty, List, Typography } from "antd";
import React, { useEffect, useState } from "react";
import TrackingDetails from "../Tracking/Tracking";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../Utils";
import { Loading } from "../loading/Loading";

export const OrderStatus = ({ oid }: any) => {
  const [compoData, setCompoData] = useState<any>([]);
  const [trackingData, setTrackingData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingTracking, SetsLoadingTracking] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await orderDataLoad(oid);
      setCompoData(data.orderData[0]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDomexTracking = async () => {
    SetsLoadingTracking(true);
    try {
      if (compoData?.tracking_number) {
        const trackig = await domexTrckingSystem(compoData?.tracking_number);
        setTrackingData(trackig);
      }
    } finally {
      SetsLoadingTracking(false);
    }
  };

  useEffect(() => {
    fetchDomexTracking();
  }, [compoData?.tracking_number]);

  useEffect(() => {
    fetchData();
  }, [oid]);

  const getStatusBadge = (status: orderStatus) => {
    switch (status) {
      case orderStatus.PENDING:
        return <Badge color="orange" text="Pending" />;
      case orderStatus.SHIPPED:
        return <Badge color="green" text="Shipped" />;
      case orderStatus.DELIVERED:
        return <Badge color="grey" text="Delivered" />;
      case orderStatus.CANCELLED:
        return <Badge color="red" text="Cancelled" />;
      default:
        return null;
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          {compoData ? (
            <div className="container-fluid">
              <div className="row col-12">
                <div className="col-12 col-md-8">
                  <div className="d-flex flex-row justify-content-around align-items-center shadow py-4">
                    <h6 className="fw-bold">Order Id : {compoData?.id}</h6>
                    <h6 className="fw-bold">
                      <span className="d-flex align-items-center">
                        <span>
                          Status : {getStatusBadge(compoData?.status)}
                        </span>
                        <span className="ms-5">
                          {compoData?.method == "whatsapp" ? (
                            <WhatsAppOutlined
                              style={{ color: "green", fontSize: 30 }}
                            />
                          ) : (
                            <CreditCardFilled
                              style={{ color: "red", fontSize: 30 }}
                            />
                          )}
                        </span>
                      </span>
                    </h6>
                  </div>
                  <div className="row my-4 mx-3">
                    <div
                      className="col-12 col-md-6 overflow-y-scroll"
                      style={{ maxHeight: "80vh" }}
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={compoData?.products}
                        renderItem={(item: any, index: any) => (
                          <List.Item
                            className="py-3 px-2 shadow my-2"
                            key={index}
                          >
                            <List.Item.Meta
                              avatar={
                                item?.images[0] ? (
                                  <Avatar
                                    src={`${IMAGE_URL}/product/${item?.images[0]}`}
                                  />
                                ) : (
                                  <Avatar icon={<UserOutlined />} />
                                )
                              }
                              title={item?.name}
                              description={`Price Rs : ${item?.price}.00 * ${
                                item?.qty
                              }(qty)   - Total Amount Rs:${
                                item?.price * item?.qty
                              }.00`}
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6 ">
                      <span className="d-flex justify-content-center flex-column mt-5 mt-md-">
                        <span className="fs-3 text-center">
                          <b>Total Amount Rs: {compoData?.amount}.00</b>
                        </span>
                        <br />
                        <span className="text-danger fs-6 text-center">
                          You are required to pay a delivery fee for courier
                          service.
                        </span>
                        <br />
                        <br />
                        <div className="mt-2">
                          <span>Customer Data:</span>
                          <br />
                          <div className="offset-1">
                            <p className="my-0">
                              <b>Name : {compoData?.customer?.name}</b>
                            </p>
                            <p className="my-0">
                              <b>Contact : {compoData?.customer?.contact}</b>
                            </p>
                            <p className="my-0">
                              <b>Address : {compoData?.customer?.address}</b>
                            </p>
                          </div>
                        </div>

                        <div className="mt-3">
                          <span>
                            Orderd Date: <b>{compoData?.date}</b>
                          </span>
                        </div>

                        <div className="mt-3">
                          <span>
                            Tracking Number:{" "}
                            <b>
                              {compoData?.tracking_number
                                ? compoData?.tracking_number
                                : "Not Added Yet"}
                            </b>
                          </span>
                        </div>

                        <div className="mt-3">
                          <span>
                            Shiped Date:{" "}
                            <b>
                              {compoData?.shipingDate
                                ? compoData?.shipingDate
                                : "Not Added Yet"}
                            </b>
                          </span>
                        </div>

                        <div className="mt-3">
                          <span>
                            Note:{" "}
                            <b>
                              {compoData?.note
                                ? compoData?.note
                                : "Not Added Yet"}
                            </b>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <h2 className="fw-bold my-4 mt-md-0">Order Tracking</h2>
                  <div
                    className="overflow-y-scroll"
                    style={{ maxHeight: "80vh" }}
                  >
                    <TrackingDetails
                      isLoadingTracking={isLoadingTracking}
                      trackingData={
                        compoData?.tracking_number != 0 && trackingData?.data
                          ? trackingData?.data[0]?.[
                              `${compoData?.tracking_number}`
                            ]
                          : []
                      }
                      trackingNumber={compoData?.tracking_number}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Empty
                className="mx-auto"
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 150 }}
                description={
                  <Typography.Text>
                    Wrong Order Id. Pleace check you Order Id.
                  </Typography.Text>
                }
              >
                <Link to={HOME_PAGE} onClick={scrollToTop}>
                  Go To Home Page
                </Link>
              </Empty>
            </>
          )}
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
};
