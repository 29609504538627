import {
  ADD_PRODUCT,
  DASHBOARD,
  FILTER_ITEMS,
  IMAGE_URL,
  MANAGE_PODUCT,
  MenuItem,
  MenuKeys,
  ORDER,
  ORDER_STATUS,
  PAYMENT_GATEWAY,
} from "../../../Constant";
import { Menu, Space, Input } from "antd";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminNav: React.FC = () => {
  const [trackingNumber, setTrackingNumber] = useState<any>();
  const navigate = useNavigate();

  const items: MenuItem[] = [
    {
      label: "Dashboard",
      key: DASHBOARD,
      icon: <i className="ri-home-2-fill"></i>,
    },
    {
      label: "Order",
      key: ORDER,
      icon: "",
    },
    {
      label: "Our Products",
      key: "SubMenu",
      icon: <i className="bi bi-caret-down-fill"></i>,
      children: [
        {
          type: "group",
          label: "Products",
          children: [
            {
              key: `./${ADD_PRODUCT}`,
              label: "Add Products",
            },
            {
              key: `./${MANAGE_PODUCT}`,
              label: "Manage Products",
            },
          ],
        },
      ],
    },
  ];

  const handleTrackingSearch = () => {
    window.open(`../${ORDER_STATUS}/${trackingNumber}`, "_blank");
  };

  return (
    <>
      <div>
        <div className="container-fluid col-12 px-0">
          <div
            className="col-12 header-top-bar text-black-50 "
            data-aos="fade-down"
            data-aos-duration="800"
            data-aos-delay="100"
            data-aos-once="true"
          ></div>
          <div className="col-12 p-3 ">
            <div className="row">
              <div className="col-12">
                <div className="row d-flex align-items-center  justify-content-around">
                  <div className="col-6 col-lg-3">
                    <img
                      src={`${IMAGE_URL}/logo.png`}
                      alt="logo"
                      className="img-fluid"
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-delay="100"
                      data-aos-once="true"
                    />
                  </div>

                  <div
                    className="col-lg-3 text-end col-5 items-center justify-content-between text-end"
                    data-aos="fade-down"
                    data-aos-duration="800"
                    data-aos-delay="100"
                    data-aos-once="true"
                  >
                    <Space
                      direction="vertical"
                      className={`mt-2 w-100 d-none d-md-block`}
                    >
                      <Input.Search
                        placeholder="Your Order ID"
                        onChange={(e) => {
                          setTrackingNumber(e.target.value);
                        }}
                        onSearch={handleTrackingSearch}
                      />
                    </Space>
                  </div>

                  <div className="d-flex flex-column flex-md-row justify-content-between my-3">
                    <Menu
                      mode="horizontal"
                      onClick={(e: any) => navigate(`${e.key}`)}
                      items={items}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNav;
