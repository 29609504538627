import React, { useEffect, useState } from "react";
import {
  Button,
  List,
  Skeleton,
  Collapse,
  Input,
  Select,
  Form,
  Badge,
} from "antd";
import {
  ArrowRightOutlined,
  CreditCardFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { ORDER_STATUS, orderStatus } from "../../Constant";
import { changeOrderStatus, orderDataLoadByStatus } from "../../Api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../Utils";

const { Panel } = Collapse;
const { Option } = Select;

const count = 4;

interface Order {
  id: string;
  customer: {
    name: string;
    address: string;
    contact: string;
  };
  status: orderStatus;
  tracking_number: string;
  method: string;
  tracking_note: string | null;
}

export const OrderListCompo = ({ compoOrderData, compoStatus }: any) => {
  const [orderData, setOrderData] = useState<Order[]>([]);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<Order[]>([]);
  const [trackingNumbers, setTrackingNumbers] = useState<{
    [key: string]: string;
  }>({});
  const [trackingNote, setTrackingNote] = useState<{ [key: string]: string }>(
    {}
  );
  const [statuses, setStatuses] = useState<{ [key: string]: orderStatus }>({});
  const [itemsToShow, setItemsToShow] = useState(count);

  useEffect(() => {
    setOrderData(compoOrderData);
  }, [compoOrderData]);

  useEffect(() => {
    if (orderData && orderData.length >= 0) {
      setInitLoading(false);

      const initialTrackingNumbers = orderData.reduce(
        (acc: any, item: Order) => {
          acc[item.id] = item.tracking_number || "";
          return acc;
        },
        {}
      );

      const initialTrackingNote = orderData.reduce((acc: any, item: Order) => {
        acc[item.id] = item.tracking_note || "";
        return acc;
      }, {});

      const initialStatuses = orderData.reduce((acc: any, item: Order) => {
        acc[item.id] = item.status || orderStatus.PENDING;
        return acc;
      }, {});

      setTrackingNumbers(initialTrackingNumbers);
      setTrackingNote(initialTrackingNote);
      setStatuses(initialStatuses);
      setList(orderData.slice(0, itemsToShow));
    }
  }, [orderData, itemsToShow]);

  const onLoadMore = () => {
    setLoading(true);
    const newItemsToShow = itemsToShow + count;
    setList(orderData?.slice(0, newItemsToShow));
    setItemsToShow(newItemsToShow);
    setLoading(false);
  };

  const handleStatusChange = (value: string, itemId: string) => {
    setStatuses((prevStatuses: any) => ({
      ...prevStatuses,
      [itemId]: value as orderStatus,
    }));
  };

  const handleTrackingNumberChange = (value: string, itemId: string) => {
    setTrackingNumbers((prevTrackingNumbers: any) => ({
      ...prevTrackingNumbers,
      [itemId]: value,
    }));
  };

  const handleTrackingNoteChange = (value: string, itemId: string) => {
    setTrackingNote((prevTrackingNote: any) => ({
      ...prevTrackingNote,
      [itemId]: value,
    }));
  };

  const loadMore =
    !initLoading && !loading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        {compoStatus != null && <Button onClick={onLoadMore}>Load More</Button>}
      </div>
    ) : null;

  const getStatusBadge = (status: orderStatus) => {
    switch (status) {
      case orderStatus.PENDING:
        return <Badge color="orange" text="Pending" />;
      case orderStatus.SHIPPED:
        return <Badge color="green" text="Shipped" />;
      case orderStatus.DELIVERED:
        return <Badge color="grey" text="Delivered" />;
      case orderStatus.CANCELLED:
        return <Badge color="red" text="Cancelled" />;
      default:
        return null;
    }
  };

  const handleOrderStatus = async (
    id: string,
    status: string,
    tracking_number: string,
    tracking_note: string
  ) => {
    if (!tracking_number) {
      toast.error("Tracking Number is missing");
      return;
    }
    if (!tracking_note) {
      toast.error("Tracking Note is missing");
      return;
    }

    try {
      await changeOrderStatus({
        id,
        status,
        tracking_number,
        tracking_note,
      });
      toast.success("Order status updated successfully!");
      if (compoStatus != null) {
        const updatedOrderData = await orderDataLoadByStatus(compoStatus);
        setOrderData(updatedOrderData?.orderData);
      }
    } catch (error) {
      toast.error("Failed to update order status");
    }
  };

  const renderOrderListDes = (item: Order) => {
    const trackingNumber = trackingNumbers[item.id] || "";
    const trackingNoteText = trackingNote[item.id] || "";
    const status = statuses[item.id] || orderStatus.PENDING;
    return (
      <div>
        <p className="my-0">
          <b>Name: {item?.customer?.name}</b>
        </p>
        <p className="my-0">
          <b>Contact: {item?.customer?.contact}</b>
        </p>
        <p className="my-0">
          <b>Address: {item?.customer?.address}</b>
        </p>
        <Collapse>
          <Panel header={getStatusBadge(status)} key="1">
            <Form layout="vertical">
              <Form.Item label="Status">
                <Select
                  defaultValue={status}
                  onChange={(value) =>
                    handleStatusChange(value as string, item.id)
                  }
                >
                  <Option value={orderStatus.PENDING}>
                    {orderStatus.PENDING}
                  </Option>
                  <Option value={orderStatus.SHIPPED}>
                    {orderStatus.SHIPPED}
                  </Option>
                  <Option value={orderStatus.DELIVERED}>
                    {orderStatus.DELIVERED}
                  </Option>
                  <Option value={orderStatus.CANCELLED}>
                    {orderStatus.CANCELLED}
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item label="Tracking Number">
                <Input
                  placeholder="Enter tracking number"
                  value={trackingNumber}
                  type="text"
                  onChange={(e) =>
                    handleTrackingNumberChange(e.target.value, item.id)
                  }
                />
              </Form.Item>
              <Form.Item label="Tracking Note">
                <Input
                  placeholder="Enter tracking note"
                  value={trackingNoteText}
                  type="text"
                  onChange={(e) =>
                    handleTrackingNoteChange(e.target.value, item.id)
                  }
                />
              </Form.Item>
              <Form.Item className="text-end">
                <Button
                  type="primary"
                  onClick={() => {
                    toast.warn("Proceeding... Please keep a moment");
                    handleOrderStatus(
                      item.id,
                      status,
                      trackingNumber,
                      trackingNoteText
                    );
                  }}
                >
                  Proceed
                </Button>
              </Form.Item>
            </Form>
          </Panel>
        </Collapse>
      </div>
    );
  };

  return (
    <List
      className="demo-loadmore-list"
      loading={initLoading}
      itemLayout="horizontal"
      loadMore={loadMore}
      dataSource={list}
      renderItem={(item: any, index: any) => (
        <List.Item
          key={index}
          actions={[
            <Link to={`../../${ORDER_STATUS}/${item?.id}`} onClick={scrollToTop} key={index} target="_blank">
              <ArrowRightOutlined />
            </Link>,
          ]}
        >
          <Skeleton avatar title={false} loading={item?.loading} active>
            <List.Item.Meta
              avatar={
                item?.method == "whatsapp" ? (
                  <WhatsAppOutlined style={{ color: "green", fontSize: 30 }} />
                ) : (
                  <CreditCardFilled style={{ color: "red", fontSize: 30 }} />
                )
              }
              title={item?.id}
              description={renderOrderListDes(item)}
            />
          </Skeleton>
        </List.Item>
      )}
    />
  );
};
