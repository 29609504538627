import React from "react";
import { Footer, NavigationBar, ScrollUpButton } from "../../Components";

const WebTermsAndCondition = () => {
  return (
    <>
      <div className="container-fluid terms-condition mt-2 mt-md-0">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h1 className="ms-3 ms-ms-0 fw-bold mb-3">
                Terms and Conditions in Asanka Motors.
              </h1>
            </div>
          </div>
        </div>

        <div>
          <p className="mx-3" data-aos="fade-down" data-aos-delay="300">
            {`Welcome to Asanka Motors. These terms and conditions outline the rules and regulations for the use of our website, accessible at Asanka Motors.
              By accessing this website, we assume you accept these terms and conditions. Do not continue to use Asanka Motors if you do not agree to take all of
              the terms and conditions stated on this page.`}
          </p>

          <div className="mx-4 my-5" data-aos="fade-down" data-aos-delay="400">
            {/* 1 Cookies */}
            <div className="my-5">
              <h4 className="font-semibold">
                <b>1.Cookies</b>
              </h4>
              <div className=" mt-1">
                <p>
                  {`We employ the use of cookies. By accessing Asanka Motors, you agreed to use cookies in agreement with the Asanka Motors's Privacy Policy.`}
                </p>
              </div>
            </div>

            {/* 2 Use of the Website */}
            <div className="my-5">
              <h4 className="font-semibold">
                <b>2.Use of the Website</b>
              </h4>
              <p>
                {`You agree to use the website only for lawful purposes and in a manner that does not infringe the rights of or restrict the use and enjoyment of the website by any third party.
                  You must not misuse this website. This includes hacking, transmitting viruses, or other malicious code.`}
              </p>
            </div>

            {/* 3 Payment and Delivery */}
            <div className="my-5">
              <h4 className="font-semibold">
                <b>3.Payment and Delivery </b>
              </h4>
              <ol className="list-disc list-inside ml-4 mt-4">
                <li>
                  Full Payment Requirement - All products must be fully paid for
                  before dispatch. This includes spare parts, modification
                  items, and helmets
                </li>
                <li>
                  Courier Payment - Customers are responsible for paying the
                  courier charges at the time of receiving the product from the
                  courier service.
                </li>
                <li>
                  Islandwide Delivery - We offer islandwide delivery across Sri
                  Lanka.
                </li>
              </ol>
            </div>

            {/* 4 Returns and Refunds */}
            <div className="my-5">
              <h4 className="font-semibold">
                <b>4.Returns and Refunds</b>
              </h4>
              <ol className="list-disc list-inside ml-4 mt-4">
                <li>
                  {`Eligibility for Returns - Returns are only applicable if the products are not unpacked. The packaging must be intact and unopened.`}
                </li>
                <li>
                  {`Return Process -  To initiate a return, customers must send an SMS with the product number provided with the product.`}
                </li>
                <li>
                  {`No Refund Policy - We do not offer refunds. Only product exchanges are allowed, subject to the return policy.`}
                </li>
              </ol>
            </div>

            {/* 5 Intellectual Property */}
            <div className="my-5">
              <h4 className="font-semibold">
                <b>5.Intellectual Property</b>
              </h4>
              <ol className="list-disc list-inside ml-4 mt-4">
                <li>
                  {`All content on this website, including text, graphics, logos, images, and software, is the property of Asanka Motors or its content
                    suppliers and is protected by Sri Lankan and international copyright laws.
                    You may not reproduce, duplicate, copy, sell, resell, or exploit any portion 
                    of the website without express written permission from Asanka Motors.
                  `}
                </li>
              </ol>
            </div>

            {/* 6 Limitation of Liability */}
            <div>
              <h4 className="font-semibold">
                <b>6.Limitation of Liability </b>
              </h4>
              <p className="mt-4">
                {` Asanka Motors shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to
                  use the website or products purchased from the website.Governing Law
                  These terms and conditions are governed by and construed in accordance with the laws of Sri Lanka, and any disputes relating to these terms
                  and conditions will be subject to the exclusive jurisdiction of the courts of Sri Lanka.`}
              </p>
            </div>

            {/* 7 Changes to Terms and Conditions */}
            <div className="my-5">
              <h4 className="font-semibold">
                <b>7.Special Needs</b>
              </h4>
              <p className="mt-4">
                {`We reserve the right to amend these terms and conditions at any time. Any changes will be posted on this page, and it is your responsibility to review these terms and conditions regularly to ensure you are aware of any changes.`}
              </p>
            </div>

            {/* 8 Privacy */}
            <div className="my-5">
              <h4 className="font-semibold">
                <b>8.Privacy</b>
              </h4>
              <p className="mt-4">
                No personal data will be shared with any others
              </p>
            </div>

            {/* 9 Contact Information */}
            <div className="my-5">
              <h4 className="font-semibold">
                <b>9.Contact Information </b>
              </h4>
              <p className="mt-4">
                If you have any questions about these terms and conditions,
                please contact us at: <br />
                <div className="ms-5">
                  Asanka Motors 964/1 E, <br /> Digana Nilagama, <br />
                  Digana, <br /> Kandy <br /> +94717798841 <br />{" "}
                  asankamotors@gmail.com
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebTermsAndCondition;
