
import { OrderListCompo } from "../../Components";
import { orderStatus } from "../../Constant";
import { orderDataLoadByStatus, orderDataLoadByStatusAndId } from "../../Api";
import { useAuth } from "../../Hook";
import { Input, Space } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import React from "react";

const OrderManage = () => {
  const { isLoggedIn }: any = useAuth();
  const [shippedOrderData, setShippedOrderData] = useState<any>();
  const [deliveredOrderData, setDeliveredOrderData] = useState<any>();
  const [cancelledOrderData, setCancelledOrderData] = useState<any>();
  const [searchOrderId, setSearchOrderId] = useState<string>("");

  const fetchData = async () => {
    const shippedOrder = await orderDataLoadByStatus(orderStatus.SHIPPED);
    const deliveredOrder = await orderDataLoadByStatus(orderStatus.DELIVERED);
    const cancelledOrder = await orderDataLoadByStatus(orderStatus.CANCELLED);

    setShippedOrderData(shippedOrder);
    setDeliveredOrderData(deliveredOrder);
    setCancelledOrderData(cancelledOrder);
  };

  const handleSearch = async (status: string) => {
    if (!searchOrderId) {
      toast.warning("Please enter an Invoice ID.");
      return;
    }

    const orderData = await orderDataLoadByStatusAndId({
      status: status,
      oid: searchOrderId,
    });

    switch (status) {
      case orderStatus.SHIPPED:
        setShippedOrderData(orderData);
        break;
      case orderStatus.DELIVERED:
        setDeliveredOrderData(orderData);
        break;
      case orderStatus.CANCELLED:
        setCancelledOrderData(orderData);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isLoggedIn()) {
      toast.warning("User is not logged in.");
    } else {
      fetchData();
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row col-12">
          <div className="col-4">
            <h2>SHIPPED ORDERS</h2>
            <Space
              direction="vertical"
              className="mt-2 w-100 d-none d-md-block"
            >
              <Input.Search
                placeholder="Search by Invoice ID"
                onChange={(e) => setSearchOrderId(e.target.value)}
                onSearch={() => handleSearch(orderStatus.SHIPPED)}
              />
            </Space>
            <div className="overflow-y-scroll" style={{ height: "60vh" }}>
              <OrderListCompo
                compoOrderData={shippedOrderData?.orderData}
                compoStatus={orderStatus.SHIPPED}
              />
            </div>
          </div>
          <div className="col-4">
            <h2>DELIVERED ORDERS</h2>
            <Space
              direction="vertical"
              className="mt-2 w-100 d-none d-md-block"
            >
              <Input.Search
                placeholder="Search by Invoice ID"
                onChange={(e) => setSearchOrderId(e.target.value)}
                onSearch={() => handleSearch(orderStatus.DELIVERED)}
              />
            </Space>
            <div className="overflow-y-scroll" style={{ height: "60vh" }}>
              <OrderListCompo
                compoOrderData={deliveredOrderData?.orderData}
                compoStatus={orderStatus.DELIVERED}
              />
            </div>
          </div>
          <div className="col-4">
            <h2>CANCELLED ORDERS</h2>
            <Space
              direction="vertical"
              className="mt-2 w-100 d-none d-md-block"
            >
              <Input.Search
                placeholder="Search by Invoice ID"
                onChange={(e) => setSearchOrderId(e.target.value)}
                onSearch={() => handleSearch(orderStatus.CANCELLED)}
              />
            </Space>
            <div className="overflow-y-scroll" style={{ height: "60vh" }}>
              <OrderListCompo
                compoOrderData={cancelledOrderData?.orderData}
                compoStatus={orderStatus.CANCELLED}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderManage;
