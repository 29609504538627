import {
  DownOutlined,
  PlusCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  ColorPicker,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../Hook";
import {
  IMAGE_URL,
  MANAGE_PODUCT,
  ProductAdditionalData,
} from "../../Constant";
import React from "react";
import {
  addColors,
  addModel,
  addTypes,
  loadtSingleProdDataForUpdate,
  seoEnhancement,
  updateProductData,
} from "../../Api";
import ReactQuill from "react-quill";
import { loadProdTypes } from "../../Api/admin/load-types";
import { hsvToHex, scrollToTop } from "../../Utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../Components";

const UpdateProduct = () => {
  const { isLoggedIn }: any = useAuth();
  const navigate = useNavigate();
  const param = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalHeader, setModelHeader] = useState<string>();
  const [additionalDataType, setAdditionalDataType] =
    useState<ProductAdditionalData>(ProductAdditionalData.TYPE);
  const [shortDes, setShortDes] = useState<any>("");
  const [description, setDes] = useState<any>("");
  const [productname, setProductname] = useState<any>("");
  const [isEnhancing, setIsEnhancing] = useState<boolean>(false);
  const [openColorPalett, setOpenColorPalett] = useState(false);
  const [price, setPrice] = useState<any>();

  //types
  const [type, setType] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [model, setModel] = useState<any>([]);
  const [colors, setColors] = useState<any>([]);

  const [selectedType, setSelectedType] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectedModel, setSelectedModel] = useState<any>(null);
  const [selectedColors, setSelectedColors] = useState<any>(null);

  const [addedType, setAddedType] = useState<any>();
  const [addedModel, setAddedModel] = useState<any>();
  const [addedColors, setAddedColors] = useState<any>();
  const [addedColorsCode, setAddedColorsCode] = useState<any>();
  const [prodStatus, setProdStatus] = useState<any>(0);
  const [loadedImage, setLoadedImage] = useState<any>();
  const [isDataLoad, setIsDataLoad] = useState<boolean>(false);

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  //image handle
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleChange = ({ fileList }: any) => setFileList(fileList);

  const handleCancel = () => setPreviewVisible(false);

  const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const fetchPageData = async () => {
    const fetchData = await loadProdTypes();
    setType(fetchData?.productType);
    setModel(fetchData?.productModel);
    setCategory(fetchData?.vehicletype);
    setColors(fetchData?.productColor);

    fetchProdData()
  };

  const fetchProdData = async () => {
    const data = await loadtSingleProdDataForUpdate(Number(param.prodid));
    const prodData = data?.productData;

    //images setup
    const fileListWithURLs = prodData?.imageArray.map((imageName) => ({
      name: imageName,
      url: `${IMAGE_URL}/product/${imageName}`,
    }));
    setFileList(fileListWithURLs);
    setLoadedImage(prodData?.imageArray);

    //data
    setProductname(prodData?.name);
    setPrice(prodData?.price);
    setShortDes(JSON.parse(prodData?.sampleDiscription).content);
    setDes(JSON.parse(prodData?.desciption).content);
    setSelectedType(prodData?.type);
    setSelectedCategory(prodData?.vehicle);
    setSelectedModel(prodData?.model);
    setSelectedColors(prodData?.colorArray);
    setProdStatus(prodData?.status);
    setIsLoading(false);
    setIsDataLoad(true);
  };

  useEffect(() => {
    
    if (!isLoggedIn()) {
      toast.warning("User is not logged in.");
    } else {
      fetchPageData();
    }
  }, [param]);

  const handleShortDescriptionEnhancement = async () => {
    setIsEnhancing(true);
    try {
      if (productname && shortDes) {
        const data = await seoEnhancement(shortDes, 50, productname);
        setShortDes(data);
      } else {
        toast.error("Fill short description");
      }
    } catch (error) {
      toast.error("Error while enhancing short description");
    } finally {
      setIsEnhancing(false);
    }
  };

  const handleDescriptionEnhancement = async () => {
    setIsEnhancing(true);
    try {
      if (productname && description) {
        const data = await seoEnhancement(description, 150, productname);
        setDes(data);
      } else {
        toast.error("Fill description");
      }
    } catch (error) {
      toast.error("Error while enhancing description");
    } finally {
      setIsEnhancing(false);
    }
  };

  const getDeletedImageNames = (originalArray: any, changedArray: any) => {
    const originalNames = originalArray.map((item) => item);
    const changedNames = changedArray.map((item) => item.name);

    const changedItems = changedNames.filter(
      (name) => !originalNames.includes(name)
    );

    const deletedItems = originalNames.filter(
      (name) => !changedNames.includes(name)
    );

    const result = [...changedItems, ...deletedItems];
    return result;
  };

  const schema = Yup.object().shape({
    fileList: Yup.array().required("Image is required"),
    productname: Yup.string().required("Product name is required"),
    price: Yup.string().required("Price is required"),
    shortDes: Yup.string().required("Short description is required"),
    description: Yup.string().required("Long description is required"),
    selectedType: Yup.string().required("Type is required"),
    selectedCategory: Yup.string().required("Category is required"),
    selectedModel: Yup.string().required("Model is required"),
    selectedColors: Yup.mixed().required("Colors are required"),
  });

  const handleAddProducts = async () => {
    setIsLoading(true);
    try {
      await schema.validate(
        {
          fileList,
          productname,
          price,
          shortDes,
          description,
          selectedType,
          selectedCategory,
          selectedModel,
          selectedColors,
        },
        { abortEarly: false }
      );

      const data = {
        pid: param.prodid,
        image: fileList,
        changedImage: getDeletedImageNames(loadedImage, fileList),
        productname: productname,
        price: price,
        shortdes: JSON.stringify({ content: shortDes }),
        longdes: JSON.stringify({ content: description }),
        type: selectedType,
        category: selectedCategory,
        model: selectedModel,
        colors: selectedColors.value,
        status: prodStatus,
      };

      const prodData = await updateProductData(data);

      if (prodData.isDataUpdated) {
        toast.success(prodData.msg);
      } else {
        toast.error(prodData.msg);
      }
    } catch (error) {
      error.errors.forEach((errorMessage) => {
        toast.error(errorMessage, {
          position: "top-right",
        });
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        fetchPageData();
      }, 3000);
    }
  };

  //handle type API
  const handleAddType = async () => {
    if (addedType) {
      const data = await addTypes(addedType);
      if (data.isAdded) {
        toast.success(data.msg);
        setType(data.productType);
        handleModalCancel();
        setAddedType(null);
      } else {
        toast.error(data.msg);
      }
    } else {
      toast.error("Fill the field!");
    }
  };

  const handleAddModel = async () => {
    if (addedModel) {
      const data = await addModel(addedModel);
      if (data.isAdded) {
        toast.success(data.msg);
        setModel(data.productModel);
        handleModalCancel();
        setAddedModel(null);
      } else {
        toast.error(data.msg);
      }
    } else {
      toast.error("Fill the field!");
    }
  };

  const handleAddColor = async () => {
    if (addedColors) {
      const code = hsvToHex(
        addedColorsCode.metaColor.originalInput.h,
        addedColorsCode.metaColor.originalInput.s,
        addedColorsCode.metaColor.originalInput.v
      );
      const data = await addColors(addedColors, code);
      if (data.isAdded) {
        toast.success(data.msg);
        setColors(data.productColor);
        handleModalCancel();
        setAddedColors(null);
      } else {
        toast.error(data.msg);
      }
    } else {
      toast.error("Fill the field!");
    }
  };

  const handleChecked = (bool: any) => {
    if (bool) {
      setProdStatus(0);
    } else {
      setProdStatus(1);
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {isDataLoad ? (
            <>
              <div className="container-fluid">
                <div className="col-12">
                  <div className="row">
                    {/* section 1 */}
                    <div className="col-3">
                      <div className="row">
                        <h3 className="fw-bold mb-3">Update Product Image</h3>
                        <div className="d-flex col-12 flex-row">
                          <Upload
                            action={`${IMAGE_URL}/empty-image.png`}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            multiple
                          >
                            {fileList?.length >= 20 ? null : (
                              <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                              </div>
                            )}
                          </Upload>
                        </div>
                        <Modal
                          visible={previewVisible}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="Preview"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                      </div>
                    </div>

                    {/* section 2 */}
                    <div className="col-9">
                      <div className="row">
                        <h3 className="fw-bold mb-3">Update Product Data</h3>

                        <Form layout="vertical">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-6">
                              <Form.Item
                                label="Product Name"
                                name="productNameUpdate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input the product name!",
                                  },
                                ]}
                              >
                                <Input
                                  name="productNameUpdatea"
                                  placeholder="Add product name"
                                  size="large"
                                  value={"test data"}
                                  defaultValue={productname}
                                  onChange={(e) =>
                                    setProductname(e.target.value)
                                  }
                                />
                              </Form.Item>

                              <Form.Item
                                className="w-50"
                                label="Product Price"
                                name="productPrice"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input the product price!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Add product price"
                                  size="large"
                                  prefix={"Rs : "}
                                  defaultValue={price}
                                  onChange={(e: any) => {
                                    setPrice(e.target.value);
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                label="Product short description"
                                name="productShortDescription"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input the product short description!",
                                  },
                                ]}
                              >
                                <ReactQuill
                                  value={shortDes}
                                  onChange={(value) => {
                                    setShortDes(value);
                                  }}
                                  placeholder="Product short description"
                                />
                                <div className="text-end">
                                  <Button
                                    type="primary"
                                    loading={isEnhancing}
                                    onClick={handleShortDescriptionEnhancement}
                                  >
                                    Description Enhancement with AI
                                  </Button>
                                </div>
                              </Form.Item>

                              <Form.Item
                                label="Product description"
                                name="productDescription"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input the product description!",
                                  },
                                ]}
                              >
                                <ReactQuill
                                  placeholder="Product description"
                                  value={description}
                                  onChange={(value) => {
                                    setDes(value);
                                  }}
                                />
                                <div className="text-end">
                                  <Button
                                    type="primary"
                                    loading={isEnhancing}
                                    onClick={handleDescriptionEnhancement}
                                  >
                                    Description Enhancement with AI
                                  </Button>
                                </div>
                              </Form.Item>
                            </div>
                            <div className="col-5">
                              <Form.Item
                                label="Product Type"
                                name="productTypeUpdate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input the product Type!",
                                  },
                                ]}
                              >
                                <div className="d-flex">
                                  <Select
                                    showSearch
                                    placeholder="Select a product Type"
                                    optionFilterProp="label"
                                    onChange={(value: any) => {
                                      setSelectedType(value);
                                    }}
                                    filterSort={(optionA: any, optionB: any) =>
                                      (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                          (optionB?.label ?? "").toLowerCase()
                                        )
                                    }
                                    options={type.map((option) => ({
                                      label: option.label,
                                      value: option.value,
                                    }))}
                                    defaultValue={
                                      type.find(
                                        (option) => option.value == selectedType
                                      )?.label
                                    }
                                  />
                                  <Button
                                    type="primary"
                                    onClick={() => {
                                      showModal();
                                      setModelHeader("type");
                                      setAdditionalDataType(
                                        ProductAdditionalData.TYPE
                                      );
                                    }}
                                  >
                                    <PlusCircleFilled />
                                  </Button>
                                </div>
                              </Form.Item>
                              <Form.Item
                                label="Product Category"
                                name="productCategory"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input the product category!",
                                  },
                                ]}
                              >
                                <div className="d-flex">
                                  <Select
                                    showSearch
                                    placeholder="Select a product category"
                                    optionFilterProp="label"
                                    onChange={(value: any) => {
                                      setSelectedCategory(value);
                                    }}
                                    filterSort={(optionA: any, optionB: any) =>
                                      (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                          (optionB?.label ?? "").toLowerCase()
                                        )
                                    }
                                    options={category.map((option) => ({
                                      label: option.label,
                                      value: option.value,
                                    }))}
                                    defaultValue={
                                      category?.find(
                                        (option) =>
                                          option.value == selectedCategory
                                      )?.label
                                    }
                                  />
                                </div>
                              </Form.Item>
                              <Form.Item
                                label="Product Model"
                                name="productModel"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input the product model!",
                                  },
                                ]}
                              >
                                <div className="d-flex">
                                  <Select
                                    showSearch
                                    placeholder="Select a product model"
                                    optionFilterProp="label"
                                    onChange={(value: any) => {
                                      setSelectedModel(value);
                                    }}
                                    filterSort={(optionA: any, optionB: any) =>
                                      (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                          (optionB?.label ?? "").toLowerCase()
                                        )
                                    }
                                    options={model.map((option) => ({
                                      label: option.label,
                                      value: option.value,
                                    }))}
                                    defaultValue={
                                      model?.find(
                                        (option) =>
                                          option.value == selectedModel
                                      )?.label
                                    }
                                  />
                                  <Button
                                    type="primary"
                                    onClick={() => {
                                      showModal();
                                      setModelHeader("model");
                                      setAdditionalDataType(
                                        ProductAdditionalData.MODAL
                                      );
                                    }}
                                  >
                                    <PlusCircleFilled />
                                  </Button>
                                </div>
                              </Form.Item>
                              <Form.Item
                                label="Product Colors"
                                name="productColors"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input the product colors!",
                                  },
                                ]}
                              >
                                <div className="d-flex">
                                  <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    optionFilterProp="label"
                                    placeholder="select colors"
                                    onChange={(value: any, lable: any) => {
                                      setSelectedColors({ value, lable });
                                    }}
                                    options={colors.map((option) => ({
                                      label: option.label,
                                      value: option.value,
                                      desc: option.desc,
                                      emoji: option.emoji,
                                    }))}
                                    defaultValue={
                                      Array.isArray(selectedColors)
                                        ? selectedColors.map(
                                            (color) => color.id
                                          )
                                        : []
                                    }
                                    optionRender={(option: any) => (
                                      <Space>
                                        <span
                                          role="img"
                                          aria-label={option?.data?.label}
                                        >
                                          {option?.data?.emoji}
                                        </span>
                                        {option?.data?.desc}
                                      </Space>
                                    )}
                                  />
                                  <Button
                                    type="primary"
                                    onClick={() => {
                                      showModal();
                                      setModelHeader("color");
                                      setAdditionalDataType(
                                        ProductAdditionalData.COLOR
                                      );
                                    }}
                                  >
                                    <PlusCircleFilled />
                                  </Button>
                                </div>
                              </Form.Item>
                              <div>
                                <Checkbox
                                  className="text-center my-3"
                                  checked={prodStatus != 1}
                                  onChange={(e) => {
                                    handleChecked(e.target.checked);
                                  }}
                                >
                                  {" "}
                                  product deactivated
                                </Checkbox>
                                <Button
                                  className="w-100"
                                  type="primary"
                                  size="large"
                                  onClick={handleAddProducts}
                                >
                                  Update Product
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                title={`Add New ${modalHeader}`}
                visible={modalVisible}
                onCancel={handleModalCancel}
                footer={null}
              >
                <>
                  {ProductAdditionalData.TYPE === additionalDataType && (
                    <>
                      <Input
                        placeholder={`Enter new ${modalHeader}`}
                        value={addedType}
                        onChange={(e: any) => {
                          setAddedType(e.target.value);
                        }}
                      />
                      <div className="text-end ">
                        <Button type="primary" onClick={handleAddType}>
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                  {ProductAdditionalData.MODAL === additionalDataType && (
                    <>
                      <Input
                        placeholder={`Enter new ${modalHeader}`}
                        value={addedModel}
                        onChange={(e: any) => {
                          setAddedModel(e.target.value);
                        }}
                      />
                      <div className="text-end ">
                        <Button type="primary" onClick={handleAddModel}>
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                  {ProductAdditionalData.COLOR === additionalDataType && (
                    <>
                      <Input
                        placeholder={`Enter new ${modalHeader}`}
                        value={addedColors}
                        onChange={(e: any) => {
                          setAddedColors(e.target.value);
                        }}
                      />
                      <ColorPicker
                        className="mt-2 "
                        defaultValue="#1677ff"
                        open={openColorPalett}
                        onOpenChange={setOpenColorPalett}
                        onChange={(e: any) => {
                          setAddedColorsCode(e);
                        }}
                        showText={() => (
                          <DownOutlined
                            rotate={openColorPalett ? 180 : 0}
                            style={{
                              color: "rgba(0, 0, 0, 0.25)",
                            }}
                          />
                        )}
                      />
                      <div className="text-end ">
                        <Button type="primary" onClick={handleAddColor}>
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                </>
              </Modal>
            </>
          ) : (
            <>
              <Empty
                className="mx-auto"
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 150 }}
                description={
                  <Typography.Text>
                    Wrong Order Id. Pleace check you Order Id.
                  </Typography.Text>
                }
              >
                <Link to={`../../${MANAGE_PODUCT}`} onClick={scrollToTop}>
                  Go To Product Page
                </Link>
              </Empty>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UpdateProduct;
