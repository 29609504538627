import { MenuProps } from "antd";
export type MenuItem = Required<MenuProps>["items"][number];

export enum MenuKeys {
  Sub1 = "sub1",
  Sub2 = "sub2",
  AllItems = 21,
  Bikes = 20,
  ThreeWheel = 22,
  Helmets = 19,
  ModificationItems = 23,
  LowToHigh = "24",
  HighToLow = "25",
}

export type CartItem = {
  id: number;
  qty: number;
  amount: number;
};

export interface CartContextType {
  cartItems: CartItem[];
  totalAmount: number;
  addToCart: (id: number, qty: number, amount: number) => void;
  removeFromCart: (id: number) => void;
  increaseQty: (id: number) => void;
  decreaseQty: (id: number) => void;
  getQtyById: (id: number) => number;
  clearCartSession: () => void;
  updateCartSession: (cartItems: CartItem[]) => void;
  fetchSessionData: () => void;
  setCartItems: (data: CartItem[]) => void;
}

export enum OrderMethod {
  Online = "online",
  Whatsapp = "whatsapp",
}

export enum orderStatus {
  PENDING = "PENDING",
  SHIPPED = "SHIPPED",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED",
}

//payHere
declare global {
  interface Window {
    payhere: any;
  }
}

export enum ProductAdditionalData {
  TYPE = "type",
  CATEGORY = "category",
  MODAL = "modal",
  COLOR = "color",
}
