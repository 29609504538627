import React from "react";
import { Card, Empty, Typography } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import moment from "moment";
import { Loading } from "../loading/Loading";
const { Text } = Typography;

const TrackingDetails: React.FC<any> = ({
  trackingData,
  trackingNumber,
  isLoadingTracking = false,
}) => {
  return (
    <>
      {!isLoadingTracking ? (
        <>
          {trackingData && trackingData.length > 0 ? (
            <Card title={`Waybill: ${trackingNumber}`}>
              <p>Status: {trackingData[0]?.status}</p>
              <div>
                Last Update:{" "}
                {moment(trackingData[0]?.tnxDate).format("YYYY-MM-DD HH:mm:ss")}
              </div>
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="my-5"
              >
                <EnvironmentOutlined
                  style={{ fontSize: "24px", marginRight: "8px" }}
                />
                <Text strong>
                  {trackingData[0]?.pickup_branch
                    ? trackingData[0]?.pickup_branch
                    : "Digana"}
                </Text>
                <span style={{ margin: "0 8px" }}>to</span>
                <EnvironmentOutlined
                  style={{ fontSize: "24px", marginRight: "8px" }}
                />
                <Text strong>
                  {trackingData[0]?.delivery_city
                    ? trackingData[0]?.delivery_city
                    : "Not Added yet"}
                </Text>
              </div>
              <h3>Tracking History</h3>
              {/* <List
            dataSource={trackingData?.tracking_history}
            renderItem={(event, index) => (
              <List.Item>
                <Text strong>
                  {moment(trackingData?.event.date).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                  :
                </Text>{" "}
                {trackingData?.event.location} - {trackingData?.event.status}
              </List.Item>
            )}
          /> */}
            </Card>
          ) : (
            <>
              <Empty
                className="mx-auto"
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 150 }}
                description={<Typography.Text>No Data</Typography.Text>}
              ></Empty>
            </>
          )}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center">
            <Loading
              fullscreen={false}
              color={"black"}
              tip={"Fetching Tracking Data ! Please wait a moment"}
            />
          </div>
        </>
      )}
    </>
  );
};

export default TrackingDetails;
