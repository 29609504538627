import React from "react";
import { ADDRESS, EMAIL, FACEBOOK, IMAGE_URL, WHATSAPP } from "../../Constant";
import { Link } from "react-router-dom";
import {
  FacebookFilled,
  MailFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
export const ContactUs = () => {
  return (
    <>
      <div className="contact-section mt-0 mt-md-3">
        <div className="container d-flex align-items-start">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="text-start section-title ms-md-0 ms-3"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                <h1
                  className="text-dark fw-bold"
                  data-aos="fade-down"
                  data-aos-duration="800"
                  data-aos-delay="100"
                  data-aos-once="true"
                >
                  Contact Us
                </h1>
                <p className="mt-4">
                  {` We'd love to hear from you! Whether you have questions about
                  our tours, need assistance with bookings, or want to share
                  feedback, our team is here to help.`}
                </p>

                <p>
                  Our customer support team is available Monday to Friday from
                  9:00 AM to 5:00 PM (local time) And{" "}
                  <b>24Hrs Available via whatsapp</b>. Feel free to reach out to
                  us via email or phone, and we will get back to you as soon as
                  possible.
                </p>

                <div className="contact-info">
                  <p className="text-start">
                    <strong>Email: </strong>
                    <i className="bi bi-envelope text-primary"></i>{" "}
                    <a href={`mailto:${EMAIL}`} className="contact-link">
                      {EMAIL}
                    </a>
                    <br />
                    <strong>Phone: </strong>
                    <i className="bi bi-whatsapp text-success"></i>{" "}
                    <a href={`tel:${WHATSAPP}`} className="contact-link">
                      {WHATSAPP}
                    </a>
                    <br />
                    <strong>Office Address: </strong>
                    <i className="bi bi-geo-alt-fill text-danger"></i>{" "}
                    <span className="contact-address">{ADDRESS}</span>
                  </p>
                </div>
                <div className="mt-2 mt-md-0">
                  <div className="my-3">
                    <Link
                      to={FACEBOOK}
                      className=" btn btn-sm btn-light bg-transparent border-0 position-relative"
                    >
                      <span className="text-primary fs-2">
                        <FacebookFilled />
                      </span>
                    </Link>
                    <Link
                      to={`https://wa.me/${WHATSAPP}`}
                      className=" btn btn-sm btn-light bg-transparent border-0 position-relative"
                    >
                      <span className="text-success fs-2">
                        <WhatsAppOutlined />
                      </span>
                    </Link>

                    <Link
                      to={`mailto:${EMAIL}`}
                      className=" btn btn-sm btn-light bg-transparent border-0 position-relative"
                    >
                      <span className="text-danger fs-2">
                        <MailFilled />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-pic"
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-delay="100"
                data-aos-once="true"
              >
                <div className="row">
                  <div className="col-12">
                    <img
                      src={`${IMAGE_URL}/contactus_image.jpg`}
                      alt=""
                      className="h-100 w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
