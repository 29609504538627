import React, { useEffect, useState, useRef } from "react";
import { Loading, PageProductList } from "../../Components";
import { MenuItem, MenuKeys } from "../../Constant";
import { filterdPageDataLoad } from "../../Api";
import {
  DollarCircleOutlined,
  FilterFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Menu, Switch } from "antd";
import { Suspense } from "react";
import { useIsMobile } from "../../Utils";
import ProductDataTable from "../../Components/Product-data-table/ProductDataTable";

const items: MenuItem[] = [
  {
    key: MenuKeys.Sub1,
    label: "Select Types",
    icon: <SettingOutlined />,
    children: [
      { key: MenuKeys.AllItems, label: "All Items" },
      { key: MenuKeys.Bikes, label: "Bikes" },
      { key: MenuKeys.ThreeWheel, label: "Three wheel" },
      { key: MenuKeys.Helmets, label: "Helmets" },
      { key: MenuKeys.ModificationItems, label: "Modification Items" },
    ],
  },
];

const sortItems: MenuItem[] = [
  {
    key: MenuKeys.Sub2,
    label: "Select Price",
    icon: <DollarCircleOutlined />,
    children: [
      { key: MenuKeys.LowToHigh, label: "Low To High" },
      { key: MenuKeys.HighToLow, label: "High To Low" },
    ],
  },
];

const Product = ({ id = 21 }: any) => {
  const isMobile = useIsMobile();
  const [filteredData, setFilteredData] = useState<any>([]);
  const [type, setType] = useState<number>(id);
  const [sort, setSort] = useState<any>(MenuKeys.HighToLow);
  const [page, setPage] = useState<number>(1);
  const [dataTableVisibility, setDataTableVisibility] =
    useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async (filtered_item: number, sort_id: number) => {
    setIsLoading(true);
    try {
      const data = await filterdPageDataLoad(filtered_item, sort_id);
      setFilteredData(data?.product);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(type, sort);
  }, [type, sort, id]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handlePagination);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handlePagination);
      }
    };
  }, [filteredData]);

  const handlePagination = () => {
    const container = containerRef.current;
    if (container) {
      const scrollPosition = container.scrollTop + container.clientHeight;
      const totalHeight = container.scrollHeight;

      if (scrollPosition >= totalHeight - 10) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handleFilterChangeType = (selectedType: number) => {
    setType(selectedType);
  };

  const handleFilterChangeSort = (selectedSort: number) => {
    setSort(selectedSort);
  };

  const handleDataDatbleVisibility = (checked: boolean) => {
    setDataTableVisibility(checked);
  };

  return (
    <>
      <Suspense fallback={<Loading />}>
        <div className="d-flex flex-column flex-md-row col-12">
          <div className="col-12 col-md-2">
            <Menu
              style={{ width: `${isMobile ? `full` : `320px`}` }}
              defaultSelectedKeys={[String(MenuKeys.AllItems)]}
              defaultOpenKeys={isMobile ? undefined : [String(MenuKeys.Sub1)]}
              mode="inline"
              items={items}
              className="position-sticky top-0"
              onClick={(e) => {
                const [selectedType] = e.keyPath.map(Number);
                handleFilterChangeType(selectedType);
              }}
            />

            <Menu
              style={{ width: `${isMobile ? "full" : `320px`}` }}
              defaultSelectedKeys={[String(MenuKeys.HighToLow)]}
              defaultOpenKeys={isMobile ? undefined : [String(MenuKeys.Sub2)]}
              mode="inline"
              items={sortItems}
              className="position-sticky top-0"
              onClick={(e) => {
                const [selectedSort] = e.keyPath.map(Number);
                handleFilterChangeSort(selectedSort);
              }}
            />
          </div>
          <div
            className="col-12 col-md-9 mx-auto mt-0 pt-0"
            style={
              !dataTableVisibility
                ? { overflowY: "auto", maxHeight: "80vh" }
                : {}
            }
          >
            <div className="text-end me-5">
              <label htmlFor="" className="me-2">
                {dataTableVisibility ? "Disable" : "Enable"} Table View
              </label>
              <Switch
                defaultChecked={false}
                onChange={handleDataDatbleVisibility}
              />
            </div>

            {dataTableVisibility ? (
              <>
                <ProductDataTable isAdmin={false} />
              </>
            ) : (
              <>
                {!isLoading ? (
                  <>
                    <PageProductList
                      ItemData={filteredData}
                      title=""
                      subtitle={``}
                      isWrap={true}
                      width={isMobile ? 400 : 300}
                    />
                  </>
                ) : (
                  <>
                    <Loading />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default Product;
