import { SERVER_LINK } from "../../Constant";
import axios from "axios";

export const updateProductData = async (dataArray: any) => {
  try {
    const formData = new FormData();
    formData.append("inputs", JSON.stringify(dataArray));
    for (let i = 0; i < dataArray.image.length; i++) {
      formData.append(`images[]`, dataArray.image[i].originFileObj);            
    }

    const dataObject = await axios.post(
      `${SERVER_LINK}/admin/update-product-data.php`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return dataObject.data;
  } catch (error: any) {
    console.error("Error fetching data:", error.message);
  }
};
