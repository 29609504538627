import { Link } from "react-router-dom";
import { ABOUT_US, CONTACT_US, EMAIL, HOME_PAGE, IMAGE_URL, PRODUCT_PAGE, TERMS_COND, WHATSAPP } from "../../Constant";
import React from "react";
import { scrollToTop } from "../../Utils";

export const Footer = () => {
  return (
    <footer
      className="container-fluid mx-0 w-100"
      data-aos="fade-down"
      data-aos-duration="800"
      data-aos-delay="100"
      data-aos-once="true"
    >
      <div
        style={{ display: "flex", height: "30px" }}
        className="row mt-5 d-flex justify-content-around"
      >
        <div className="col-4" style={{ backgroundColor: "red" }}></div>
        <div className="col-8" style={{ backgroundColor: "blue" }}></div>
      </div>

      <div className="col-12 mt-3 pt-3">
        <div className="row">
          <div className="col-12 pb-1">
            <div className="row d-flex align-items-start justify-content-start justify-content-md-center">
              <div
                className="col-6 col-md-4 mb-4 mb-md-0"
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <span className="text-primary fs-4 fw-bold">
                  Store Location
                </span>
                <br />
                <br />
                <p className="product-para">
                  {" "}
                  964/1 E, <br />
                  Digana Nilagama,
                  <br />
                  Digana,
                  <br />
                  Kandy{" "}
                </p>

                <p className="product-para">{WHATSAPP}</p>

                <p>{EMAIL}</p>
              </div>

              <div className="row d-flex flex-row justify-content-around col-12 col-md-4 mb-4 mb-md-0">
                {/* <!-- usefull link --> */}
                <div
                  className="col-12"
                  data-aos="fade-down"
                  data-aos-duration="800"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  <span className="text-primary fs-4 fw-bold">
                    Useful Links
                  </span>
                  <br />
                  <br />
                  <Link to={HOME_PAGE} onClick={scrollToTop} className="text-decoration-none">
                    {" "}
                    <p className="product-para">Home</p>
                  </Link>
                  <Link to={PRODUCT_PAGE} onClick={scrollToTop} className="text-decoration-none">
                    <p className="product-para">Products</p>
                  </Link>
                  <Link to={ABOUT_US} onClick={scrollToTop} className="text-decoration-none">
                    <p className="product-para">About Us</p>
                  </Link>
                  <Link to={CONTACT_US} onClick={scrollToTop} className="text-decoration-none">
                    <p className="product-para">Contact Us</p>
                  </Link>
                  <Link to={TERMS_COND} onClick={scrollToTop} className="text-decoration-none">
                    <p className="product-para">Terms And Condition</p>
                  </Link>
                </div>
              </div>

              <div
                className="col-12 col-md-4 mb-4 mb-md-0"
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <span className="text-primary fs-4 fw-bold">About Us</span>
                <br />
                <br />
                <div className="col-8">
                  <img
                    src={`${IMAGE_URL}/logo.png`}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <br />
                <p className="">
                 {` Welcome to our Latest Product Section, where innovation and
                  excellence meet to bring you the cutting-edge solutions you've
                  been waiting for! Explore our newest arrivals, handpicked to
                  elevate your experience and cater to your evolving needs.`}
                </p>
                <a
                  href="mailto:dilukshagamage1@gmail.com"
                  className="text-decoration-none"
                ></a>
                <div className="col-11">
                  <a href="https://www.payhere.lk" target="_blank">
                    <img
                      src="https://www.payhere.lk/downloads/images/payhere_long_banner.png"
                      alt="PayHere"
                      width="300"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 bg-secondary p-4 text-center">
            Copyright &copy; <span>{new Date().getFullYear()} </span> TaproDev |
            All Rights Recerved
          </div>
        </div>
      </div>
    </footer>
  );
};
