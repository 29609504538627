import React from "react";
import "./compo.css";
import { IMAGE_URL } from "../../../Constant";
import { Avatar, Card } from "antd";
import Meta from "antd/es/card/Meta";

const customerData = [
  {
    img: "customer-1.png",
    name: "Tharindu lakshan",
    post: "Software engineer",
    note: `Asanka Motors has been my go-to for bike parts for years. The quality and durability of their products are unmatched. Their customer service is top-notch, making every purchase a hassle-free experience.`,
  },
  {
    img: "customer-2.jpg",
    name: "Sahan Madusha",
    post: " - ",
    note: `I recently upgraded my 3-wheeler with parts from Asanka Motors, and I couldn’t be happier. The selection is fantastic, and the parts fit perfectly. I highly recommend them for anyone looking to maintain their vehicle in top condition.`,
  },
  {
    img: "customer-3.jpg",
    name: "Kavindu Dissanayake",
    post: "OWNER AND FOUNDER OF Kavindu Motors",
    note: `Working in the automotive industry, I need reliable suppliers. Asanka Motors has consistently provided me with high-quality parts that keep my clients satisfied. Their fast shipping and competitive prices are a big plus.`,
  },
];

export const HomePageCustomer = () => {
  return (
    <div className="col-12 my-5 mx-0 container-fluid">
      <div className={`text-start container`}>
        <h2
          className="text-dark fs-2 fw-bold"
          data-aos="fade-down"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-once="true"
        >
          Customer Testimonials
        </h2>
        <p
          className="sub-text fs-6 me-md-5"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-once="true"
        >
          {`Hear what our customers have to say about their experiences with Asanka Motors. From quality products to exceptional service, our customers' feedback speaks volumes. Discover how we've helped countless riders keep their vehicles in peak condition and why they trust us for all their spare parts needs.`}
        </p>
      </div>

      <div className="row">
        <div className="col-12 mx-auto px-0">
          <div className="row d-flex flex-wrap justify-content-center pb-4">
            <div
              className="row col-10 d-flex flex-column justify-content-center flex-md-row mx-auto my-3 px-0"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="100"
              data-aos-once="true"
            >
              {customerData.map((customer: any, index: any) => (
                <Card
                  key={index}
                  style={{ width: 400 }}
                  cover={
                    <img alt="example" src={`${IMAGE_URL}/${customer.img}`} />
                  }
                  className="shadow float-center mx-auto my-2"
                >
                  <Meta
                    avatar={<Avatar src={`${IMAGE_URL}/${customer.img}`} />}
                    title={`Mr.${customer.name}`}
                    description={`${customer.note}`}
                  />
                  <span className="fw-bold float-end mt-2">
                    {customer.post}
                  </span>
                </Card>
              ))}
            </div>
            <div>
              <p className="text-center mx-auto w-75 mt-3">
                Welcome to our Latest Product Section, where innovation and
                excellence meet to bring you the cutting-edge solutions you have
                been waiting for! Explore our newest arrivals, handpicked to
                elevate your experience and cater to your evolving needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
