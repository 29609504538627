import { Loading, OrderStatus } from "../../Components";
import React from "react";
import { Suspense } from "react";
import { useParams } from "react-router-dom";

const Orders = () => {
  const params = useParams();

  return (
    <>
      <Suspense fallback={<Loading />}>
        <div>
          <OrderStatus oid={params.orderid} />
        </div>
      </Suspense>
    </>
  );
};

export default Orders;
