import React from "react";
import { Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const Loading = ({
  tip = "Loading...",
  fullscreen = true,
  color = "white",
}: any) => {
  return (
    <Space className="text-center">
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 50, color: color }} spin />
        }
        tip={tip}
        size="large"
        fullscreen={fullscreen}
      />
    </Space>
  );
};
