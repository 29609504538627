import axios from "axios";
import { SERVER_LINK } from "../../../Constant";

export const addTypes = async (type:any) => {
  const data = {
    type: type,
  };
  try {
    const dataObject = await axios.get(
      `${SERVER_LINK}/admin/category/add-type.php?inputs=${JSON.stringify(
        data
      )}`
    );
    return dataObject.data;
  } catch (error:any) {
    console.error("Error fetching data:", error.message);
    return null;
  }  
};