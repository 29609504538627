import { Button } from "antd";
import React from "react";
import { Cart } from "../cart/Cart";
import { useCartContext } from "../../Context";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

export const QtyItem = ({ productData, isNote = true }: any) => {
  const context = useCartContext();

  return (
    <>
      <div>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <div className="mx-3 fs-4">
            <Cart productid={productData.id} amount={productData.price} />
          </div>
          <div className="mx-3">
            <div className="qty-container d-flex align-items-center">
              <Button
                size="middle"
                type="default"
                icon={<MinusCircleOutlined />}
                onClick={() => {
                  context.decreaseQty(productData.id);
                }}
                disabled={context.getQtyById(productData.id) <= 1}
              />
              <span className="px-3" style={{ margin: "0 8px" }}>
                {context.getQtyById(productData.id)}
              </span>
              <Button
                size="middle"
                type="default"
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  context.increaseQty(productData.id);
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 text-center">
          <span>
            <span className={isNote ? "fs-3" : "fs-6"}>
              {" "}
              <b>Total Amount</b>{" "}
            </span>{" "}
            <br />{" "}
            <span className={isNote ? "fs-3 fw-bold" : "fs-6 fw-bold"}>
              Rs: {(productData.price as number) * context.getQtyById(productData.id)}.00
            </span>
            <br />
            <span className="text-danger fs-6">
              {isNote &&
                "You are required to pay a delivery fee for courier service."}
            </span>
          </span>
        </div>
      </div>
    </>
  );
};
