// config.js
// const webLink = "http://localhost:3000";
const webLink = "https://asankamotors.lk";

const config = {
  webName: "Asanka Motors",

  facebook: "https://web.facebook.com/profile.php?id=61560034121554",
  instergram: "https://www.instagram.com/beachsrilankatravel/",

  address: "Asanka Motors 964/1 E, Digana Nilagama,Digana,kandy<sri lanka",
  mobile: "+94717798841",
  email: "asankamotors@gmail.com",
  whatsapp: "+94717798841",
  devsSite: "https://taprodev.com/",

  webLink: webLink,
  server: "https://asankamotors.lk/server",
  imagepath: `https://asankamotors.lk/server/images`,
  // server: "http://localhost/app/asankamotors/server",
  // imagepath: `http://localhost/app/asankamotors/server/images`,
  noImage: "No_Image_Available.png",

  //routs
  home: "/",
  productPage: "products",
  productSinglePage: "product-single-view",
  cartpage: "cart",
  filterItems: "filteritem",
  aboutus: "aboutus",
  contactus: "contactus",
  terms: "terms_and_condition",
  order: "order_status",
  signin: "signin",
  dashboard: "dashboard",
  manageproduct: "manageproduct",
  orderList: "order",
  addproduct: "add-product",
  updateproduct: "update-product",
  paymentgateway: "https://support.payhere.lk/sandbox-and-testing",
  adminsignin: "asanka-motors-admin-signin",
  adminboard: "asanka-motors-admin-portal",
};
export default config;
