import React from "react";
import { useEffect, useState } from "react";
import { CartFill } from "react-bootstrap-icons";
import { useCartContext } from "../../Context";

export const Cart = ({ productid, amount }: any) => {
  const context = useCartContext();
  const [isItemAdded, setIsItemAdded] = useState<boolean>(false);

  const handleItemAddCart = (productid: number, amount: number) => {
    setIsItemAdded(true);
    context.addToCart(productid, 1, amount * 1);
  };

  const handleItemRemoveCart = (productid: number) => {
    const updatedCartItems = context.cartItems.filter(
      (item: any) => item.id !== productid
    );
    context.updateCartSession(updatedCartItems);
    setIsItemAdded(false);
  };

  useEffect(() => {
    const isProductInCart = context.cartItems.some(
      (item: any) => item.id === productid
    );
    if (isProductInCart) {
      setIsItemAdded(true);
    }
  }, [context.cartItems]);

  return (
    <>
      {isItemAdded ? (
        <>
          <CartFill
            style={{ color: "red" }}
            onClick={() => {
              handleItemRemoveCart(productid);
            }}
          />
        </>
      ) : (
        <>
          <CartFill
            onClick={() => {
              handleItemAddCart(productid, amount);
            }}
          />
        </>
      )}
    </>
  );
};
