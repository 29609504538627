import React from "react";
import AdminNav from "./adminNave/adminNav";
import WebNav from "./webNav/webNav";


interface INavProp{
  isAdmin:boolean
}

export const NavigationBar = (props:INavProp) => {
  return (
    <>
      {props.isAdmin ? <AdminNav /> : <WebNav />}
    </>
  );
};
