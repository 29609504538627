import {
  ABOUT_US,
  CART_PAGE,
  CONTACT_US,
  EMAIL,
  FACEBOOK,
  FILTER_ITEMS,
  HOME_PAGE,
  IMAGE_URL,
  MenuItem,
  MenuKeys,
  ORDER_STATUS,
  PRODUCT_PAGE,
  TERMS_COND,
  WHATSAPP,
} from "../../../Constant";
import {
  FacebookFilled,
  MailFilled,
  ShoppingCartOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Menu, Space, Input } from "antd";

import { useState } from "react";
import { useCartContext } from "../../../Context";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import ProductSearch from "../../product-search/ProductSearch";
import { scrollToTop } from "../../../Utils";

const WebNav: React.FC = () => {
  const navigate = useNavigate();
  const data = useCartContext();
  const [trackingNumber, setTrackingNumber] = useState<any>();

  const items: MenuItem[] = [
    {
      label: "Home",
      key: HOME_PAGE,
      icon: <i className="ri-home-2-fill"></i>,
    },
    {
      label: "Product",
      key: PRODUCT_PAGE,
      icon: "",
    },
    {
      label: "About Us",
      key: ABOUT_US,
      icon: "",
    },
    {
      label: "Contact Us",
      key: CONTACT_US,
      icon: "",
    },
    {
      label: "Terms & Condition",
      key: TERMS_COND,
      icon: "",
    },
  ];

  const handleTrackingSearch = () => {
    navigate(`../${ORDER_STATUS}/${trackingNumber}`);
  };

  return (
    <>
      <div>
        <div
          className="container-fluid col-12 px-0"
          data-aos="fade-down"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-once="true"
        >
          <div className="col-12 header-top-bar text-black-50 ">
            <div className="row col-12 d-flex flex-column flex-lg-row justify-content-lg-center mx-auto ">
              <div className="col-lg-5 col-12 d-flex justify-content-center justify-content-lg-start align-items-start mt-3">
                <Link
                  to="/"
                  onClick={scrollToTop}
                  className="text-decoration-none text-black-50 me-2"
                >
                  Home
                </Link>
                |
                <Link
                  to={CONTACT_US}
                  onClick={scrollToTop}
                  className="text-decoration-none text-black-50 mx-2"
                >
                  Contact
                </Link>
                |
                <Link
                  to={ABOUT_US}
                  onClick={scrollToTop}
                  className="text-decoration-none text-black-50 mx-2"
                >
                  About Us |
                </Link>
                <a
                  href={FACEBOOK}
                  target="_blank"
                  className="mx-1 cursor-pointer"
                >
                  <FacebookFilled />
                </a>
                <a
                  href={`https://wa.me/${WHATSAPP}`}
                  target="_blank"
                  className="mx-1 cursor-pointer text-success"
                >
                  <WhatsAppOutlined />
                </a>
                <a
                  href={`mailto:${EMAIL}`}
                  className="mx-1 text-danger cursor-pointer"
                >
                  <MailFilled />
                </a>
              </div>
              <div className="col-lg-5 col-12 text-lg-end text-center mt-3 fs-6">
                Premium Quality, Unbeatable Price: Your Source for the Best
                Parts!
              </div>
            </div>
          </div>
          <div className="col-12 px-3 pt-3 ">
            <div className="row">
              <div className="col-12">
                <div className="row d-flex align-items-center  justify-content-around">
                  <div className="col-6 col-lg-3">
                    <img
                      src={`${IMAGE_URL}/logo.png`}
                      alt="logo"
                      className="img-fluid"
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-delay="100"
                      data-aos-once="true"
                    />
                  </div>

                  <div
                    className="col-lg-3 text-end col-5 items-center justify-content-between text-end"
                    data-aos="fade-down"
                    data-aos-duration="800"
                    data-aos-delay="100"
                    data-aos-once="true"
                  >
                    <Space
                      direction="vertical"
                      className="mt-2 w-100 d-none d-md-block"
                    >
                      <Input.Search
                        placeholder="Your Order ID"
                        onChange={(e) => {
                          setTrackingNumber(e.target.value);
                        }}
                        onSearch={handleTrackingSearch}
                      />
                    </Space>

                    <div className="d-inline d-md-none">
                      <Link
                        to={CART_PAGE}
                        onClick={scrollToTop}
                        className=" btn btn-sm btn-light bg-transparent border-0 position-relative"
                      >
                        <span className="text-primary fs-2">
                          <ShoppingCartOutlined />
                        </span>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {data.cartItems.length}
                        </span>
                      </Link>
                      <span className="fw-bold ms-3">
                        Rs:{data.totalAmount}.00
                      </span>
                    </div>
                  </div>

                  <div className="d-flex flex-column flex-md-row justify-content-between my-3">
                    <Menu
                      mode="horizontal"
                      onClick={(e: any) => navigate(`../${e.key}`)}
                      items={items}
                    />
                    <div className="d-flex align-items-center justify-content-center justify-content-md-end me-3 mt-3 mt-md-0">
                      <div className="d-flex row col-12 col-md-10 flex-column">
                        <Space
                          direction="vertical"
                          className="d-block d-md-none mb-2"
                        >
                          <Input.Search
                            placeholder="Your Order ID"
                            onChange={(e) => setTrackingNumber(e.target.value)}
                            onSearch={handleTrackingSearch}
                          />
                        </Space>

                        <ProductSearch isAdmin={false} />
                      </div>

                      <div className="d-none d-md-inline col-5">
                        <Link
                          to={CART_PAGE}
                          onClick={scrollToTop}
                          className="btn btn-sm btn-light bg-transparent border-0 position-relative"
                        >
                          <span className="text-primary fs-2">
                            <ShoppingCartOutlined />
                          </span>
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {data.cartItems.length}
                          </span>
                        </Link>
                        <span className="fw-bold ms-3">
                          Rs:{data.totalAmount}.00
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="text-end my-0 py-0"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebNav;
