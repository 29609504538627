import { GoogleGenerativeAI } from "@google/generative-ai";

const genAI = new GoogleGenerativeAI("AIzaSyDRKoQJpbuSKBZyxe91nSeci4I72cTF4Rs");
const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

export const seoEnhancement = async (
  description: string,
  wordcount = 200,
  productname: string
): Promise<string> => {
  const prompt = `Craft an SEO-optimized product description for a ${productname} at our authorized three-wheel bike spare part shop in Sri Lanka with a maximum word count of ${wordcount}(do not overflow). Emphasize our genuine parts, budget-friendly options, and expertise as an authorized dealer in Kandy. Ensure the content resonates with search engines, highlighting our commitment to quality and affordability.(also give suitable html content only): ${description}.`;
  const result = await model.generateContent(prompt);
  const response = result.response;
  const text = await response.text();
  return text;
};
