
import React from "react";
import { ORDER, orderStatus } from "../../Constant";
import { adminDashboardDataLoad } from "../../Api";
import { BellFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../Hook";
import { Line } from "@ant-design/charts";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../Utils";
import { OrderListCompo } from "../../Components";

const Dashboard = () => {
  const { isLoggedIn }: any = useAuth();
  const [dashBoardData, setDashBoardData] = useState<any>();

  const fetchData = async () => {
    const data = await adminDashboardDataLoad();
    setDashBoardData(data);
  };

  useEffect(() => {
    if (!isLoggedIn()) {
      toast.warning("User is not logged in.");
    } else {
      fetchData();
    }
  }, []);

  const config = {
    data: dashBoardData?.incomeChart
      ? dashBoardData.incomeChart.map((item: any) => ({
          ...item,
          monthly_income: Number(item.monthly_income),
        }))
      : [],
    height: 400,
    xField: "month",
    yField: "monthly_income",
    tooltip: {
      formatter: (data: any) => {
        return {
          name: data.month,
          value: `Monthly Income: ${data?.monthly_income}`,
        };
      },
      customContent: (data: any) => {
        return `<div>${data
          ?.map((item: any) => {
            return `<div class="tooltip-chart">
                      <span class="tooltip-item-name"> ${item?.month}</span>
                      <span class="tooltip-item-value">Monthly Income: ${item?.monthly_income}</span>
                    </div>`;
          })
          .join("")}</div>`;
      },
      showMarkers: true,
      showContent: true,
      position: "right",
      showCrosshairs: true,
    },
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-11 mb-4 shadow mx-auto">
                <Line {...config} />
              </div>

              <div>
                <Row gutter={16}>
                  <Col span={8} className="shadow">
                    <Card title="Total Income" bordered={false}>
                      <h1 className="text-center">
                        Rs:{dashBoardData?.totalIncome}.00
                      </h1>
                    </Card>
                  </Col>
                  <Col span={8} className="shadow">
                    <Card title="Total Order" bordered={false}>
                      <h1 className="text-center">
                        {dashBoardData?.totalOrder}
                      </h1>
                    </Card>
                  </Col>
                  <Col span={8} className="shadow">
                    <Card title="Total Pending Order" bordered={false}>
                      <h1 className="text-center">
                        {dashBoardData?.totalPendingOrder}
                      </h1>
                    </Card>
                  </Col>
                </Row>

                <br />

                <Row gutter={16}>
                  <Col span={8} className="shadow">
                    <Card title="Total Products" bordered={false}>
                      <h1 className="text-center">
                        {dashBoardData?.totalProduct}
                      </h1>
                    </Card>
                  </Col>
                  <Col span={8} className="shadow">
                    <Card title="Total Shiped Product" bordered={false}>
                      <h1 className="text-center">
                        {dashBoardData?.totalShipedItem}
                      </h1>
                    </Card>
                  </Col>
                  <Col span={8} className="shadow">
                    <Card title="WhatApp / Online Orders" bordered={false}>
                      <h4 className="text-center">
                        WhatsApp Order : {dashBoardData?.whatsappOrder}
                      </h4>
                      <h4 className="text-center">
                        Online Order : {dashBoardData?.onlineOrder}
                      </h4>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center justify-content-between me-3 mb-2 mt-md-0">
              <div>
                <h2>
                  <b>Pending Orders</b>
                </h2>
              </div>
              <div className="d-md-inline">
                <Link
                  to={`../${ORDER}`}
                  onClick={scrollToTop}
                  className="btn btn-sm btn-light bg-transparent border-0 position-relative"
                >
                  <span className="text-primary fs-2">
                    <BellFilled />
                  </span>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {dashBoardData?.pendingOrders.length}
                  </span>
                </Link>
              </div>
            </div>
            <div className="overflow-y-scroll" style={{ height: "100vh" }}>
              <OrderListCompo
                compoOrderData={dashBoardData?.pendingOrders}
                compoStatus={orderStatus.PENDING}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
