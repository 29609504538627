import React from "react";
import { IMAGE_URL } from "../../../Constant";
import { Carousel } from "antd";

export const HeaderSection = () => {
  return (
    <>
      <div
        data-aos="fade-down"
        data-aos-duration="800"
        data-aos-delay="100"
        data-aos-once="true"
      >
        <Carousel autoplay>
          <div>
            <img
              src={`${IMAGE_URL}/carousel-img-1.png`}
              className="d-block w-100"
            />
          </div>
          <div>
            <img
              src={`${IMAGE_URL}/carousel-img-2.png`}
              className="d-block w-100"
            />
          </div>
          <div>
            <img
              src={`${IMAGE_URL}/carousel-img-3.png`}
              className="d-block w-100"
            />
          </div>
        </Carousel>
      </div>
    </>
  );
};
