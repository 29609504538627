import React, { useEffect, useState } from "react";
import { Space, Table, TableProps, Tag } from "antd";
import TruncatedTextWithTooltip from "../TruncatedTextWithTooltip/TruncatedTextWithTooltip";
import { loadProductDataForTable } from "../../Api";
import { useNavigate } from "react-router-dom";
import {
  PRODUCT_SINGLE_PAGE,
  UPDATE_PRODUCT,
} from "../../Constant";
import { scrollToTop } from "../../Utils";
import { Loading } from "../loading/Loading";

interface DataType {
  id: any;
  key: string;
  name: string;
  price: number;
  vehicle: string;
  status: number;
  tags: string[];
}

const ProductDataTable = ({ isAdmin = false }) => {
  const [tableData, setTbaleData] = useState<any>();
  const [filterVehicle, setFilterVehicle] = useState<any>();
  const navigate = useNavigate();

  const fetchData = async () => {
    const data = await loadProductDataForTable();
    setTbaleData(data?.product);
    setFilterVehicle(data?.model);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleVisit = (id: any) => {
    if (isAdmin) {
      navigate(`../${UPDATE_PRODUCT}/${id}`);
    } else {
      navigate(`../${PRODUCT_SINGLE_PAGE}/${id}`);
    }
    scrollToTop()
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Space size="middle">
          <TruncatedTextWithTooltip text={record.name} maxLength={20} />
        </Space>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Price(Rs:)",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      key: "vehicle",
      filters: filterVehicle?.map((vehicle) => ({
        text: vehicle.text,
        value: vehicle.value,
      })),
      onFilter: (value, record) => record.vehicle == value,
      sorter: (a, b) => a.vehicle.length - b.vehicle.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      onFilter: (value, record) => record.name.indexOf(value as string) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag color={status == 1 ? "green" : "red"}>
            {status == 1 ? "Active" : "Deactivate"}
          </Tag>
        </>
      ),
      hidden: !isAdmin,
      filters: [
        {
          text: "Active",
          value: "1",
        },
        {
          text: "Deactivcate",
          value: "0",
        },
      ],
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status - b.status,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleVisit(record.id);
            }}
          >
            Click
          </span>
        </Space>
      ),
    },
  ];

  const data: DataType[] = tableData?.map((item, index) => ({
    key: (index + 1).toString(),
    name: item.title,
    price: `Rs:${parseFloat(item.price)}.00`,
    vehicle: item.tags[1],
    status: item.status,
    tags: item.tags,
    id: item.id,
  }));

  return (
    <>
      {data ? (
        <>
          <Table columns={columns} dataSource={data} />
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
};

export default ProductDataTable;
