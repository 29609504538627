import { CartItem } from "../Constant";
import { useEffect, useState, useCallback } from "react";

export const useCart = ()  => {
  let initialCartItems: CartItem[] = [];

  useEffect(() => {
    if (typeof window !== "undefined" && typeof sessionStorage !== "undefined") {
      const storedCartItems = JSON.parse(sessionStorage.getItem("cartItems") || "[]");
      setCartItems(storedCartItems);
    }
  }, []);

  const [cartItems, setCartItems] = useState<CartItem[]>(initialCartItems);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const updateCartSession = (cartItems: CartItem[]) => {
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
    setCartItems(cartItems);
    fetchSessionData()
  };

  const addToCart = useCallback((id: number, qty: number, amount: number) => {
    const newItem: CartItem = { id, qty, amount };
    const existingItemIndex = cartItems.findIndex(
      (item: CartItem) => item.id === id
    );

    const cartItemsString = sessionStorage.getItem("cartItems");
    let cartItems_sess: CartItem[] = cartItemsString
      ? JSON.parse(cartItemsString)
      : [];

    if (existingItemIndex !== -1) {
      removeFromCart(id);
      cartItems_sess = cartItems_sess.filter((item: CartItem) => item.id !== id);
      cartItems_sess.push(newItem);
    } else {
      cartItems_sess.push(newItem);
    }

    updateCartSession(cartItems_sess);
    fetchSessionData()
  }, [cartItems]);

  const removeFromCart = useCallback((id: number) => {
    setCartItems((prevCartItems: CartItem[]) => {
      const updatedCartItems = prevCartItems.filter((item: CartItem) => item.id !== id);
      sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      return updatedCartItems;
    });
    fetchSessionData()
  }, []);

  const increaseQty = useCallback((id: number) => {
    fetchSessionData()
    setCartItems((prevCartItems: CartItem[]) => {
      const updatedCartItems = prevCartItems.map((item: CartItem) =>
        item.id === id ? { ...item, qty: item.qty + 1 } : item
      );
      updateCartSession(updatedCartItems);
      return updatedCartItems;
    });
  }, []);

  const decreaseQty = useCallback((id: number) => {
    fetchSessionData()
    setCartItems((prevCartItems: CartItem[]) => {
      const updatedCartItems = prevCartItems.map((item: CartItem) =>
        item.id === id ? { ...item, qty: Math.max(item.qty - 1, 0) } : item
      );
      const filteredCartItems = updatedCartItems.filter((item: CartItem) => item.qty > 0);
      updateCartSession(filteredCartItems);
      return filteredCartItems;
    });
  }, []);

  const getQtyById = useCallback((id: number) => {
      const item = cartItems.find((item: CartItem) => item.id === id);
      return item ? item.qty : 1;
  },[cartItems]);

  useEffect(() => {
    let total = 0;
    cartItems.forEach((item: CartItem) => {
      total += item.qty * item.amount;
    });
    setTotalAmount(total);
  }, [cartItems]);

  const fetchSessionData = () => {
    const cartItemsString = sessionStorage.getItem("cartItems");
    let cartItems: CartItem[] = cartItemsString ? JSON.parse(cartItemsString) : [];
    setCartItems(cartItems);
  };  

  const clearCartSession = () => {
    sessionStorage.removeItem("cartItems");
    setCartItems([]);
    fetchSessionData();
  };

  return {
    addToCart,
    removeFromCart,
    cartItems,
    totalAmount,
    increaseQty,
    decreaseQty,
    getQtyById,
    setCartItems,
    updateCartSession,
    clearCartSession,
    fetchSessionData,
  };
};
