import React from 'react';
import { Tooltip } from 'antd';
import { removeHtmlTags, truncate } from '../../Utils';

const TruncatedTextWithTooltip = ({ text, maxLength }) => {
  const truncatedText = removeHtmlTags(truncate(text, maxLength));
  return (
    <Tooltip title={removeHtmlTags(text)}>
      <span>{truncatedText}</span>
    </Tooltip>
  );
};

export default TruncatedTextWithTooltip;
