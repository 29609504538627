import { SERVER_LINK } from "../../Constant";
import axios from "axios";

export const homePageDataLoad = async () => {
  try {
    const dataObject = await axios.get(
      `${SERVER_LINK}/home/load-home-product.php`
    );
    return dataObject.data;
  } catch (error: any) {
    console.error("Error fetching data:", error.message);
  }
};
