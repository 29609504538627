import React from "react";
import "./pageProductList.css";
import { Itemcard } from "../item-card/item-card";
import { Loading } from "../loading/Loading";

export const PageProductList = (props: any) => {
  return (
    <div className="col-12 my-5 mx-0 container-fluid">
      <div className={`text-start container`}>
        <h2
          className="text-dark fs-2 fw-bold"
          data-aos="fade-down"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-once="true"
        >
          {props?.title}
        </h2>
        <p
          className="sub-text fs-6 me-md-5"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-once="true"
        >
          {props?.subtitle}
        </p>
      </div>

      <div className="row">
        <div className="col-12 px-0">
          <div
            className={`mx-auto item-list-container ${
              props.isWrap && props.isWrap ? "isWrap" : "isNoWrap"
            } `}
          >
            {props?.ItemData ? (
              <>
                {props?.ItemData?.map((data: any, index: number) => (
                  <>
                    <Itemcard
                      key={index}
                      title={data.title}
                      sampleDis={data.sampleDis}
                      price={Number(data.price)}
                      productid={data.id}
                      productImage={data.image}
                      width={props.width && props.width ? props.width : 240}
                    />
                  </>
                ))}
              </>
            ) : (
              <>
                <Loading color={"blue"} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
