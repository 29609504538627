import { Gear, Stopwatch, Truck } from "react-bootstrap-icons";
import "./compo.css";
import { useIsMobile } from "../../../Utils";
import React from "react";
export const Service = () => {
  const isMobile = useIsMobile();

  return (
    <div className="container mb-5">
      <div
        id="aboutUs"
        className="col-12 d-flex flex-column flex-lg-column justify-content-between mx-auto shadow mt-0 mt-md-5 border-0 rounded p-4"
      >
        <div className="row ">
          <div className="col-12 col-lg-4 my-2">
            <div
              className="row my-0 d-flex flex-row align-items-center justify-content-center aboutUs-short"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="100"
              data-aos-once="true"
            >
              <div className="col-3">
                <p className="text-center aboutUs-short-icon text-danger">
                  <Gear />
                </p>
              </div>
              <div className="col-9 aboutUs-short-content text-start">
                <h3 className={`${isMobile ? "fs-6" : "fs-4"}`}>
                  Genuine Spare Parts{" "}
                </h3>
                <p className="fs-7">
                  Best Quality & Genuine motor bike and threewheel spare parts.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 my-2">
            <div
              className="row d-flex flex-row align-items-center justify-content-center aboutUs-short "
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <div className="col-3">
                <p className="text-center aboutUs-short-icon">
                  <Stopwatch />
                </p>
              </div>
              <div className="col-9 aboutUs-short-content text-start">
                <h3 className={`${isMobile ? "fs-6" : "fs-4"}`}>
                  24/7 Service
                </h3>
                <p className="fs-7">
                  Contact us 24 hours a day. Email, Whatsapp , Mobile etc.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 my-2">
            <div
              className="row d-flex flex-row align-items-center justify-content-center aboutUs-short"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="300"
              data-aos-once="true"
            >
              <div className="col-3">
                <p className="text-center aboutUs-short-icon">
                  <Truck />
                </p>
              </div>
              <div className="col-9 aboutUs-short-content text-start">
                <h3 className={`${isMobile ? "fs-6" : "fs-4"}`}>
                  Island Wide Delivery{" "}
                </h3>
                <p className="fs-7">
                  We offer island wide delivery service & Many deliver options
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
