import axios from "axios";
import { SERVER_LINK } from "../../Constant";

export const adminDashboardDataLoad = async () => {
  try {
    const dataObject = await axios.get(
      `${SERVER_LINK}/admin/load-dashboard-data.php`
    );
    return dataObject.data;
  } catch (error: any) {
    console.error("Error fetching data:", error.message);
  }
};
