import { SERVER_LINK } from "../../Constant";
import axios from "axios";

export const orderDataLoadByStatusAndId = async ({ status, oid }:any) => {
  const data = {
    status: status,
    oid: oid
  };
  try {
    const dataObject = await axios.get(
      `${SERVER_LINK}/admin/load-order-by-status-id.php?inputs=${JSON.stringify(
        data
      )}`
    );
    return dataObject.data;
  } catch (error:any) {
    console.error("Error fetching data:", error.message);
    return null;
  }
};