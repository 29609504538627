import { useNavigate } from "react-router-dom";
import { CART_PAGE, EMAIL, ORDER_STATUS, WEB_LINK } from "../Constant";
import { useCartContext } from "../Context";
import { generateHashValue, insertOrderData } from "../Api";
import { toast } from "react-toastify";

export const usePaymentModal = () => {
  const cartContext = useCartContext();
  const navigate = useNavigate();

  const proceedToPay = async (paymentData: any) => {
    const code = await generateHashValue({
      orderId: paymentData?.orderId,
      totalAmount: cartContext.totalAmount,
    });

    const payment = {
      sandbox: true,
      //@ts-ignore
      merchant_id: "1223312",
      return_url: `${WEB_LINK}/${ORDER_STATUS}/${paymentData?.orderId}`,
      cancel_url: `${WEB_LINK}/${CART_PAGE}`,
      notify_url: `${WEB_LINK}/${CART_PAGE}`,
      order_id: paymentData?.orderId,
      items: paymentData?.orderId,
      amount: cartContext?.totalAmount,
      //@ts-ignore
      currency: "LKR",
      first_name: paymentData?.customerData?.name,
      last_name: paymentData?.customerData?.name,
      email: EMAIL,
      phone: paymentData?.customerData?.contact,
      address: paymentData?.customerData?.address,
      city: "kandy",
      country: "sri lanka",
      hash: code.hash,
    };

    window.payhere.onCompleted = async function onCompleted(orderId: any) {
      try {
        const payData = await insertOrderData(paymentData);
        if (payData?.orderId != 0) {
          toast.success(
            "Your order has been placed successfully. Thank you for shopping with us."
          );
          setTimeout(() => {
            cartContext.clearCartSession();
            navigate(`../${ORDER_STATUS}/${orderId}`);
          }, 900);
        } else {
          toast.error("Somthing Went Wrong.Please Try Again Later");
        }
      } catch (error) {
        toast.error("Somthing Went Wrong.Please Try Again Later");
      }
    };

    window.payhere.onDismissed = function onDismissed() {
      toast.dismiss("Payment dismissed");
    };

    window.payhere.onError = function onError(error: any) {
      toast.error("Somthing Went wrong");
    };

    window.payhere.startPayment(payment);
  };

  return {
    proceedToPay,
  };
};
