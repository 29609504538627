import axios from "axios";

export const domexTrckingSystem = async (id: any) => {
  try {
    const response = await axios.post(
      `https://apps.domexweb.com/api/orderTracking`,
      {
        way_bill_numbers: id,
      },
      {
        headers: {
          apiToken: "f5b165303a1ed3d37d90ec8e0c6ef5bf",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error("Error fetching data:", error.message);
  }
};
