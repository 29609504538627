import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { CartProvider } from "./Context";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';

ReactDOM.render(
  <>
    <React.StrictMode>
      <CartProvider>
        <App />
      </CartProvider>
      </React.StrictMode>
    ,
  </>,
  document.getElementById("root")
);
