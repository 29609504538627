import config from "./config";

export const WEBNAME = config.webName;
export const FACEBOOK = config.facebook;
export const INSTERGRAM = config.instergram;
export const ADDRESS = config.address;
export const EMAIL = config.email;
export const WHATSAPP = config.whatsapp;

export const WEB_LINK = config.webLink;
export const SERVER_LINK = config.server;
export const IMAGE_URL = config.imagepath;
export const NO_IMAGE = config.noImage;
export const DEVS_SITE = config.devsSite;

//routs
export const HOME_PAGE = config.home;
export const PRODUCT_PAGE = config.productPage;
export const PRODUCT_SINGLE_PAGE = config.productSinglePage;
export const CART_PAGE = config.cartpage;
export const FILTER_ITEMS = config.filterItems;
export const ABOUT_US = config.aboutus;
export const CONTACT_US = config.contactus;
export const TERMS_COND = config.terms;
export const ORDER_STATUS = config.order;
export const DASHBOARD = config.dashboard;
export const ORDER = config.orderList;
export const ADD_PRODUCT = config.addproduct;
export const PAYMENT_GATEWAY = config.paymentgateway;
export const SIGN_IN = config.signin;
export const ADMIN_SIGNIN = config.adminsignin;
export const ADMIN_BOARD = config.adminboard;
export const MANAGE_PODUCT = config.manageproduct;
export const UPDATE_PRODUCT = config.updateproduct;
