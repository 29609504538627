import { SERVER_LINK } from "../../Constant";
import axios from "axios";

export const changeOrderStatus = async ({
  id,
  status,
  tracking_number,
  tracking_note,
}: {
  id: any;
  status: string;
  tracking_number: string;
  tracking_note: string;
}) => {
  const data = {
    orderId: id,
    status: status,
    tracking_number: tracking_number,
    tracking_note: tracking_note,
  };

  try {
    const response = await axios.get(
      `${SERVER_LINK}/admin/update-order-status.php`,
      {
        params: {
          inputs: JSON.stringify(data)
        }
      }
    );
    return response.data;
  } catch (error: any) {
    console.error("Error fetching data:", error.message);
  }
};
