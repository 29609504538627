import React from "react";
import { Carousel } from "antd";
import { IMAGE_URL } from "../../../Constant";

export const HomePartnership = () => {
  const slides = [
    "slide-1.png",
    "slide-2.png",
    "slide-3.png",
    "slide-4.png",
    "slide-5.png",
    "slide-6.png",
  ];

  return (
    <>
      {/* for mobile */}
      <div className="col-12 mx-auto px-0 my-5 d-md-none" style={{width:'95%'}}>
        <div
          className="row px-0"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-once="true"
        >
          <Carousel autoplay className="px-0">
            {slides.map((slide, index) => (
              <div key={index} className="carousel-item px-0">
                <div className="row px-0">
                  <div className="col-md-2 col-12 px-0">
                    <img
                      src={`${IMAGE_URL}/${slide}`}
                      className="d-block w-100 px-0"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      {/* for descktop */}
      <div className="col-12 container-fluid mx-auto px-0 my-5 d-none d-md-block" style={{width:'90%'}}>
        <div
          className="row"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-once="true"
        >
          <Carousel autoplay className="px-0">
            <div className="carousel-item">
              <div className="row">
                {slides.map((slide, index) => (
                  <div key={index} className="col-md-2 col-12">
                    <img
                      src={`${IMAGE_URL}/${slide}`}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="carousel-item px-0">
              <div className="row">
                {slides.reverse().map((slide, index) => (
                  <div key={index} className="col-md-2 col-12">
                    <img
                      src={`${IMAGE_URL}/${slide}`}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};
