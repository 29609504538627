import { CART_PAGE, HOME_PAGE, IMAGE_URL } from "../../Constant";
import { Button, Empty, Image, Spin } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import "./singleProduct.css";
import { Loading, PageProductList, QtyItem } from "../../Components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { singlePageDataLoad } from "../../Api";
import { scrollToTop } from "../../Utils";

const Singleproduct = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [productData, setProductData] = useState<any>();
  const [similarProductData, setSimilarProductData] = useState<any>();
  const [mainImage, setMainImage] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  const dataFetch = async () => {
    setIsLoading(true);
    try {
      const data = await singlePageDataLoad(Number(params.productid));
      setProductData(data?.productData);
      setSimilarProductData(data?.similerItemData);
      setMainImage(data?.productData?.imageArray[0]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.productid && !Number.isNaN(Number(params.productid))) {
      dataFetch();
    }
  }, [params]);

  const handleImageClick = (image: any) => {
    setMainImage(image);
  };

  const handleProceedTopay = () => {
    navigate(`../${CART_PAGE}`);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  return (
    <>
      <Suspense fallback={<Loading />}>
        {!isLoading ? (
          <>
            {productData ? (
              <>
                <div className="d-flex flex-column flex-md-row container-fluid">
                  <div className="col-12 d-flex flex-column col-md-6 mx-1">
                    <div className="text-center mx-auto my-3 d-md-none d-block">
                      <h1 className="fw-bold text-uppercase">
                        {productData?.name}
                      </h1>
                    </div>
                    <div className="d-flex">
                      <div className="col-7">
                        <Spin spinning={imageLoading}>
                          <Image
                            src={`${IMAGE_URL}/product/${mainImage}`}
                            alt={productData?.name as string}
                            className="w-100 "
                          />
                        </Spin>
                        <Spin spinning={imageLoading}>
                          <Image
                            src={`${IMAGE_URL}/product/${mainImage}`}
                            alt={productData?.name as string}
                            className="w-100 mt-2"
                            style={{ transform: "rotate(180deg)" }}
                            onLoad={handleImageLoad}
                          />
                        </Spin>
                      </div>
                      <div className="col-4 mx-auto d-flex align-items-start">
                        <div className="image-list">
                          {productData?.imageArray?.map(
                            (image: any, index: any) => (
                              <Spin spinning={imageLoading} key={index}>
                                <Image
                                  src={`${IMAGE_URL}/product/${image}`}
                                  alt={productData.name as string}
                                  className="mb-2 img-fluid"
                                  style={{ width: "100%", cursor: "pointer" }}
                                  onLoad={handleImageLoad}
                                  onClick={() => handleImageClick(image)}
                                />
                              </Spin>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 d-flex col-md-6 mx-auto ">
                    <div className="col-11 mx-auto">
                      <div className="text-center mx-auto d-md-block d-none">
                        <h1 className="fw-bold text-uppercase">
                          {productData?.name}
                        </h1>
                      </div>

                      <div className="mx-2 mx-md-0">
                        <span
                          className="text-center mt-3"
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(productData?.sampleDiscription).content,
                          }}
                        ></span>
                        <span
                          className="text-center mt-5"
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(productData?.desciption).content,
                          }}
                        ></span>
                      </div>

                      <div className="d-flex mt-5 flex-column flex-md-row mx-3 mx-md-0">
                        <div className="col-12 col-md-4">
                          <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              Vehical Type
                              <span className="text-capitalize">
                                {productData.vehicle}
                              </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              Product Type
                              <span className="text-capitalize">
                                {productData.type}
                              </span>
                            </li>
                          </ul>

                          <ul className="list-group mt-3">
                            <label>Colors : </label>
                            {productData.colorArray ? (
                              <>
                                {productData.colorArray.map(
                                  (color: any, index: any) => (
                                    <li
                                      key={index}
                                      className="list-group-item d-flex justify-content-between align-items-center"
                                    >
                                      <div
                                        className="color-circle "
                                        style={{
                                          backgroundColor: color.code,
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "50%",
                                          margin: "5px",
                                        }}
                                      ></div>
                                      <span className="text-capitalize">
                                        {color.name}
                                      </span>
                                    </li>
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                <span className="text-secondary fw-light text-center">
                                  No colors available
                                </span>
                              </>
                            )}
                          </ul>
                        </div>

                        <div className="col-12 col-md-8 mt-5 mt-md-0">
                          <h1 className="text-center fw-bolder">
                            Rs : {productData.price}.00
                          </h1>

                          {/* add cart and qty */}
                          <QtyItem productData={productData} />

                          {/* payment option */}
                          <div className="d-flex justify-content-center">
                            <Button
                              size="large"
                              className="w-75 my-3 shadow"
                              type="primary"
                              onClick={() => {
                                handleProceedTopay();
                                scrollToTop();
                              }}
                            >
                              Proceed to pay
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* simileritems */}
                <PageProductList
                  ItemData={similarProductData}
                  title="Similar Products"
                  subtitle={` Welcome to our Latest Product Section, where innovation and excellence
                          meet to bring you the cutting-edge solutions you've been
                          waiting for! Explore our newest arrivals, handpicked to elevate your
                          experience and cater to your evolving needs.`}
                />
              </>
            ) : (
              <>
                <Empty
                  description="No item found in this id. please follow correct method"
                  image={`${IMAGE_URL}/warning_image.png`}
                  imageStyle={{ height: "50vh" }}
                  style={{
                    height: "60vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to={HOME_PAGE}
                    onClick={scrollToTop}
                    className="btn btn-sm border border-1 rounded border-primary"
                  >
                    Back to Home Page
                  </Link>
                </Empty>
              </>
            )}
          </>
        ) : (
          <>
            <Loading />
          </>
        )}
      </Suspense>
    </>
  );
};

export default Singleproduct;
