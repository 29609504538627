import { ADMIN_BOARD, DASHBOARD, HOME_PAGE } from "../Constant";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSignIn } from "../Api";

interface UserSession {
  username: string;
  isAdmin: boolean;
}

export const useAuth = () => {
  const initialUserSession: UserSession = {
    username: "",
    isAdmin: false,
  };
  const navigate = useNavigate();

  const [userData, setUserData] = useState<UserSession>(initialUserSession);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof sessionStorage !== "undefined") {
      const storedUserData = JSON.parse(sessionStorage.getItem("asankawebuser") || "{}");
      setUserData(storedUserData);
    }
  }, []);

  const CheckingAuthApi = async (values: any) => {
    try {
      const data = await useSignIn(values);
      if (data?.isUserExists) {
        toast.success(data.msg || "Login successful");
        const userSession: UserSession = {
          username: values.email,
          isAdmin: data.isAdmin || false,
        };
        sessionStorage.setItem("asankawebuser", JSON.stringify(userSession));
        setUserData(userSession);
        navigate(`../../${ADMIN_BOARD}/${DASHBOARD}`);
      } else {
        toast.warn(data?.msg || "User does not exist");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const isLoggedIn = (): boolean => {
    if (typeof window !== "undefined" && typeof sessionStorage !== "undefined") {
      const storedUserData = sessionStorage.getItem("asankawebuser");
      if (storedUserData) {
        return true;
      } else {
        navigate(HOME_PAGE);
        return false;
      }
    }
    return false;
  };

  const isAdmin = (): boolean => {
    return userData.isAdmin;
  };

  return { CheckingAuthApi, userData, isLoggedIn, isAdmin };
};
