import { SERVER_LINK } from "../../Constant";
import axios from "axios";

export const loadtSingleProdDataForUpdate = async (id:any) => {
  const data = {
    pid: id,
  };
  try {
    const dataObject = await axios.get(
      `${SERVER_LINK}/admin/load-single-proddata-update.php?inputs=${JSON.stringify(data)}`
    );
    return dataObject.data;
  } catch (error: any) {
    console.error("Error fetching data:", error.message);
  }
};
