import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, Tooltip, notification } from "antd";
import {
  ORDER_STATUS,
  OrderMethod,
  TERMS_COND,
  WEB_LINK,
  orderStatus,
} from "../../Constant";
import {
  CreditCardFilled,
  EnvironmentFilled,
  MobileFilled,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { insertOrderData } from "../../Api";
import { generateOrderId, scrollToTop } from "../../Utils";
import { usePaymentModal } from "../../Hook";
import { useCartContext } from "../../Context";
import { Link, useNavigate } from "react-router-dom";

export const PaymentModal = () => {
  const context = useCartContext();
  const { proceedToPay } = usePaymentModal();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [name, setName] = useState<String>("");
  const [contact, setContact] = useState<String>("");
  const [address, setAddress] = useState<String>("");
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [termsChecked, setTermsChecked] = useState<boolean>(false);

  const [dataArray, setDataArray] = useState({
    orderId: generateOrderId(),
    orderData: context.cartItems,
    amount: context.totalAmount,
    customerData: {
      name: name,
      contact: contact,
      address: address,
    },
    method: "",
    status: orderStatus.PENDING,
  });

  // Function to reassign method
  const reassignMethod = (newMethod: any) => {
    setDataArray((prevState) => ({
      ...prevState,
      method: newMethod,
    }));
  };

  useEffect(() => {
    setDataArray((prevData) => ({
      ...prevData,
      customerData: {
        name,
        contact,
        address,
      },
    }));
  }, [name, contact, address]);

  useEffect(() => {
    setIsDisable(!(name && contact && address && termsChecked ));
  }, [name, contact, address, termsChecked]);

  // useEffect to handle order methods
  useEffect(() => {
    if (dataArray.method === OrderMethod.Online) {
      handleOnlineOrder();
    } else if (dataArray.method === OrderMethod.Whatsapp) {
      handleWhatAppOrder();
    }
  }, [dataArray.method]);

  const openNotificationWhatsAppOrder = (
    message: string,
    description: string,
    color: string
  ) => {
    api.open({
      message: message,
      description: description,
      icon: <WhatsAppOutlined style={{ color: color }} />,
      showProgress: true,
    });
  };

  const handleValidation = () => {
    if (name != "" && contact != "" && address != "" && termsChecked) {
      setIsDisable(!isDisable);
    } else {
      setIsDisable(true);
    }
  };

  useEffect(() => {
    handleValidation();
  }, [name, contact, address, termsChecked]);

  const handleWhatAppOrder = async () => {
    setIsDisable(true);
    setIsLoading(true);
    //API call
    try {
      const orderData = await insertOrderData(dataArray);
      if (orderData.orderId != 0) {
        openNotificationWhatsAppOrder(
          "Your Order Is Placed",
          "Your order has been placed successfully. Thank you for shopping with us.",
          "#108ee9"
        );
        setTimeout(() => {
          context.clearCartSession();
          navigate(`../${ORDER_STATUS}/${orderData.orderId}`);
          setIsDisable(false);
          setIsLoading(true);
        }, 1500);
      } else {
        openNotificationWhatsAppOrder(
          "Somthing Went Wrong.",
          "Somthing Went Wrong.Please Try Again Later",
          "red"
        );
      }
    } catch (error) {
      openNotificationWhatsAppOrder(
        "Somthing Went Wrong.",
        "Somthing Went Wrong.Please Try Again Later",
        "red"
      );
    } finally {
      setIsDisable(false);
      setIsLoading(false);
    }
  };

  const handleOnlineOrder = async () => {
    setIsDisable(true);
    setIsLoading(true);
    try {
      await proceedToPay(dataArray);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
      setIsLoading(false);
    }
  };

  function handleNameKeyDown(event) {
    const key = event.key;
    const isLetter = /^[a-zA-Z\s]*$/.test(key);

    if (!isLetter && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      event.preventDefault();
    }
  }

  const handlePhoneKeyPress = (event) => {
    const key = event.key;
    const inputValue = event.target.value;
    const isValidInput = /^[0-9\b]+$/.test(key);
    const newLength = inputValue.length + (key !== '\b' ? 1 : 0);

    if ((newLength === 1 && key !== '0') || newLength > 10 || !isValidInput) {
      event.preventDefault();
    }
  };


  return (
    <>
      {contextHolder}
      <div className="shadow border border-0 rounded p-3 my-2">
        <h2 className="fs-3 fw-bolder text-center my-3">
          Total Amount Rs:{context.totalAmount}.00
        </h2>
        <p className="text-danger">
          You are required to pay a delivery fee for courier service.
        </p>
        <div className="d-flex flex-column justify-content-center text-center mx-auto">
          <Checkbox onChange={(e) => setTermsChecked(e.target.checked)} className="mx-auto">
            I agree to the{" "}
            <span>
              <Link to={TERMS_COND} onClick={scrollToTop}>terms and conditions</Link>
            </span>
          </Checkbox>
          <span>
            <small>
              <i>Please read our terms and conditions before agreeing.</i>
            </small>
          </span>
        </div>
        <div className="mt-3">
          <span className="text-danger">
            <i>
              <small>All fields are requred *</small>
            </i>
          </span>
          <div className="d-flex">
            <Input
              size="large"
              placeholder="Enter Your Name"
              type="text"
              prefix={<UserOutlined />}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onKeyDown={handleNameKeyDown} 
            />
            <Input
              size="large"
              type="number"
              placeholder="Enter Your Contact Number"
              prefix={<MobileFilled />}
              onChange={(e) => {
                setContact(e.target.value);
              }}
              onKeyDown={handlePhoneKeyPress}
            />
          </div>
          <div className="">
            <Input
              size="large"
              placeholder="Enter Your Address"
              prefix={<EnvironmentFilled />}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="my-5">
          <Tooltip
            placement="top"
            title={`${
              isDisable
                ? isLoading
                  ? "Please Wait a Moment"
                  : "Please read the Terms and Conditions and click on the checkbox at the top to accept our conditions and Fill out your details in the form before proceeding with your order."
                : "Not available at the moment" //"Make a payment online through Payhere."
            }`}
            arrow={true}
          >
            <Button
              type="default"
              shape="round"
              icon={<CreditCardFilled />}
              size={"large"}
              className="w-100"
              // disabled={isDisable}
              disabled
              loading={isLoading}
              onClick={() => {
                reassignMethod(OrderMethod.Online);
              }}
            >
              Pay Online
            </Button>
          </Tooltip>

          <Tooltip
            placement="top"
            title={`${
              isDisable
                ? isLoading
                  ? "Please Wait a Moment"
                  : "Please read the Terms and Conditions and click on the checkbox at the top to accept our conditions and Fill out your details in the form before proceeding with your order."
                : "Upload a payment slip when you place an order. We do not accept Cash on Delivery (COD)."
            }`}
            arrow={true}
            className="mt-4"
          >
            <Button
              className="border border-1 bg-success text-light fw-bold w-100"
              shape="round"
              icon={<WhatsAppOutlined />}
              size={"large"}
              disabled={isDisable}
              loading={isLoading}
              onClick={() => {
                reassignMethod(OrderMethod.Whatsapp);
              }}
            >
              Order Via Whatsapp
            </Button>
          </Tooltip>

          {isDisable && (
            <span className="text-danger">
              <small>
                <i>
                  Please read the Terms and Conditions and click on the checkbox
                  at the top to accept our conditions and Fill out your details
                  in the form before proceeding with your order.
                </i>
              </small>
            </span>
          )}
        </div>
      </div>
    </>
  );
};
