import axios from "axios";
import { SERVER_LINK } from "../../../Constant";

export const addModel = async (model:any) => {
  const data = {
    model: model,
  };
  try {
    const dataObject = await axios.get(
      `${SERVER_LINK}/admin/category/add-model.php?inputs=${JSON.stringify(
        data
      )}`
    );
    return dataObject.data;
  } catch (error:any) {
    console.error("Error fetching data:", error.message);
    return null;
  }
};