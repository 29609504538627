import React from "react";
import { useEffect } from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  AboutUs,
  ContactUs,
  Footer,
  NavigationBar,
  ScrollUpButton,
} from "./Components";
import {
  ABOUT_US,
  ADD_PRODUCT,
  ADMIN_BOARD,
  ADMIN_SIGNIN,
  CART_PAGE,
  CONTACT_US,
  DASHBOARD,
  HOME_PAGE,
  MANAGE_PODUCT,
  ORDER,
  ORDER_STATUS,
  PRODUCT_PAGE,
  PRODUCT_SINGLE_PAGE,
  SIGN_IN,
  TERMS_COND,
  UPDATE_PRODUCT,
} from "./Constant";
import HomePage from "./Page/Home/HomePage";
import Product from "./Page/Products/Products";
import WebTermsAndCondition from "./Page/terms_and_condition/Terms";
import Singleproduct from "./Page/Single-product-view/Singleproduct";
import Orders from "./Page/Order_status/Orderstatus";
import Cart from "./Page/Cart/Cart";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "./Page/signin/SignIn";
import Dashboard from "./Page/dashboard/Dashboard";
import OrderManage from "./Page/order/OrderManage";
import AddProduct from "./Page/add-product/AddProduct";
import ProductsManage from "./Page/Manage-product/ManageProduct";
import UpdateProduct from "./Page/update-product/UpdateProduct";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 300,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={
            <>
              <NavigationBar isAdmin={false} />
              <Routes>
                <Route path={HOME_PAGE} element={<HomePage />} />
                <Route path={PRODUCT_PAGE} element={<Product />} />
                <Route path={ABOUT_US} element={<AboutUs />} />
                <Route path={CONTACT_US} element={<ContactUs />} />
                <Route path={TERMS_COND} element={<WebTermsAndCondition />} />
                <Route
                  path={`${PRODUCT_SINGLE_PAGE}/:productid`}
                  element={<Singleproduct />}
                />
                <Route path={`${ORDER_STATUS}/:orderid`} element={<Orders />} />
                <Route path={CART_PAGE} element={<Cart />} />
              </Routes>
              <ToastContainer />
              <ScrollUpButton />
              <Footer />
            </>
          }
        />
        <Route
          path={`/${ADMIN_SIGNIN}/*`}
          element={
            <>
              <Routes>
                <Route path={SIGN_IN} element={<SignIn />} />
              </Routes>
            </>
          }
        />
        <Route
          path={`/${ADMIN_BOARD}/*`}
          element={
            <>
              <NavigationBar isAdmin={true} />
              <Routes>
                <Route path={DASHBOARD} element={<Dashboard />} />
                <Route path={ORDER} element={<OrderManage />} />
                <Route path={ADD_PRODUCT} element={<AddProduct />} />
                <Route path={`${UPDATE_PRODUCT}/:prodid`} element={<UpdateProduct />} />
                <Route path={MANAGE_PODUCT} element={<ProductsManage />} />
              </Routes>
              <ToastContainer />
              <ScrollUpButton />
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
