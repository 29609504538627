import React from "react";
import { IMAGE_URL } from "../../Constant";

export const AboutUs = () => {
  return (
    <>
      <section className="aboutus-section spad mt-md-5 my-0">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-12"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              <div className="about-text ms-md-0 ms-3">
                <div
                  className="section-title"
                  data-aos="fade-down"
                  data-aos-delay="300"
                >
                  <h1
                    className="text-dark fw-bold"
                    data-aos="fade-down"
                    data-aos-duration="800"
                    data-aos-delay="100"
                    data-aos-once="true"
                  >
                    Connecting You with us.
                  </h1>
                </div>
                <p className="f-para">
                  {`Welcome to Asanka Motors, where your vehicle's performance and reliability are our top priority. We specialize in providing high-quality spare parts for bikes and 3-wheelers, ensuring that your ride stays smooth and safe. Whether you're looking for engine components, tires, or accessories, our expert team is here to help you find exactly what you need.`}
                  <br />
                  {`At Asanka Motors, we are dedicated to offering a wide range of reliable and durable parts to keep your vehicle in top condition. Our platform makes it easy to find and purchase the parts you need, all backed by our commitment to customer satisfaction. Join us and keep your bike or 3-wheeler running at its best with the trusted support of Asanka Motors.`}
                </p>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-down" data-aos-delay="400">
              <div className="about-pic">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={`${IMAGE_URL}/aboutus-image.jpg`}
                      alt=""
                      className="h-100 w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
