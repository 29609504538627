import { Card, Spin, Image as AntdImage } from "antd";
import React, { useState } from "react";
import { IMAGE_URL, PRODUCT_PAGE, PRODUCT_SINGLE_PAGE } from "../../Constant";
import { scrollToTop, truncate } from "../../Utils";
import Meta from "antd/es/card/Meta";
import { Link } from "react-router-dom";
import { Cart } from "../cart/Cart";
import { ArrowUpRightCircleFill } from "react-bootstrap-icons";
import TruncatedTextWithTooltip from "../TruncatedTextWithTooltip/TruncatedTextWithTooltip";

interface ItemCardProps {
  title: string;
  price: number;
  productid: number;
  productImage: string;
  sampleDis?: string;
  width?: number;
}

export const Itemcard = ({
  title,
  price,
  productid,
  productImage,
  sampleDis,
  width = 250,
}: ItemCardProps) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const renderImage = () => {
    return (
      <Spin spinning={loading}>
        <AntdImage
          alt={title}
          src={`${IMAGE_URL}/product/${productImage}`}
          className="product-image mt-2"
          onLoad={handleImageLoad}
        />
      </Spin>
    );
  };

  return (
    <Card
      hoverable
      style={{ width: width }}
      cover={renderImage()}
      onLoad={handleImageLoad}
      className={`item-card row mx-3 my-1`}
      loading={loading}
    >
      <Meta
        title={<TruncatedTextWithTooltip text={title} maxLength={50} />}
        description={
          <TruncatedTextWithTooltip text={JSON.parse(sampleDis).content} maxLength={100} />
        }
      />
      <div className="mt-3">
        <span className="item-price my-2 fw-bold">Rs:{price.toFixed(2)}</span>
      </div>

      <div className="float-end">
        <span className="fs-4 me-2">
          <Cart productid={productid} amount={price}/>
        </span>
        <Link
          to={`/${PRODUCT_SINGLE_PAGE}/${productid}`}
          onClick={scrollToTop}
          className="text-primary fs-4 ms-2"
        >
          <ArrowUpRightCircleFill />
        </Link>
      </div>
    </Card>
  );
};
