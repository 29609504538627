import { useMediaQuery } from "@mui/material";

export const useIsMobile = () => {
    return useMediaQuery("(max-width:500px)");
};

export const truncate = (str:string, n:number) => {
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const generateOrderId = () => {
  const date = new Date();
  const year = String(date.getFullYear()).slice(-2);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const randomDigits = String(Math.floor(100 + Math.random() * 900));
  return `${year}${month}${day}${randomDigits}`;
}

// Function to convert HSV to RGB
const hsvToRgb = (h, s, v) => {
  let r, g, b;
  let i = Math.floor(h * 6);
  let f = h * 6 - i;
  let p = v * (1 - s);
  let q = v * (1 - f * s);
  let t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
    default:
      r = 0;
      g = 0;
      b = 0;
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

// Function to convert RGB to Hex
const rgbToHex = (r, g, b) => {
  const toHex = (c) => c.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// Function to convert HSV to Hex
export const hsvToHex = (h, s, v) => {
  const [r, g, b] = hsvToRgb(h / 360, s, v);
  return rgbToHex(r, g, b);
};

export const removeHtmlTags = (input:any) => {
  return input.replace(/<[^>]*>?/gm, ''); // Remove HTML tags
}