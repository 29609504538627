import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { searchDataLoad } from "../../Api";
import { useNavigate } from "react-router-dom";
import { ADD_PRODUCT, PRODUCT_SINGLE_PAGE, UPDATE_PRODUCT } from "../../Constant";
import { useIsMobile } from "../../Utils";

const ProductSearch = (props: any) => {
  const [searchData, setSearchData] = useState<any>([]);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const fetchData = async () => {
    const data = await searchDataLoad();
    setSearchData(data?.options);
  };

  const handleSearch = (id: any) => {
    if (props?.isAdmin) {
      navigate(`../${UPDATE_PRODUCT}/${id}`);
    } else {
      navigate(`${PRODUCT_SINGLE_PAGE}/${id}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Select
        style={{ width: isMobile ? "50vh" : "75vh" }}
        showSearch
        placeholder="Search product here"
        filterOption={(input: any, option: any) =>
          (option?.label ?? "")?.toLowerCase().includes(input.toLowerCase())
        }
        options={searchData}
        onChange={(e: any) => {
          handleSearch(e);
        }}
      />
    </>
  );
};

export default ProductSearch;
